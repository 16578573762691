import axios from 'axios';

// axios.defaults.baseURL = 'http://45.82.65.172:5006/';
// axios.defaults.baseURL = 'http://localhost:5006/';
axios.defaults.baseURL = '/api';

axios.defaults.headers = {

    "Access-Control-Allow-Origin": "*"
};

const httpClient = {
    postApi: async (url, data, token) => {
        const res = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem('jt_access_token'),
            }
        });
        return res;
    },
    deleteApi: async (url, token) => {
        const res = await axios.delete(url, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem('jt_access_token'),
            }
        });
        return res;
    },
    getApi: async (url, token) => {
        const res = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem('jt_access_token'),
            }
        });
        return res;

    },
    uploadApi: async (url, data, token) => {
        const res = await axios.post(url, data, token, {
            headers: {
                Authorization: localStorage.getItem('jt_access_token'),
                "Content-Type": "multipart/form-data"
            }
        });
        return res;
    },
    patchApi: async (url, data, token) => {
        const res = await axios.patch(url, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem('jt_access_token'),
            }
        });
        return res;
    },
};

export default httpClient;