import { configureStore , getDefaultMiddleware   } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import messageReducer from './slices/messageSlice';
import { applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

const reducers = {
    auth:authReducer,
    message:messageReducer
}
export const store = configureStore({
    reducer:reducers,
    devTools:true,
    middleware:(getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    })
});


