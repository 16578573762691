import React from 'react'
import logoIcon from '../assets/icons/logo.svg'
import { useNavigate } from "react-router-dom";

const LogoPage = () => {
  let n = 0;

  const Navigate = useNavigate()
  const Circle = () => {
    return (<div className='circle-container'>
      <div className='circle' style={{
        width: `${110 - (3.25 * n)}vw`,
        height: `${110 - (3.25 * n++)}vw`,
      }}></div>
    </div>)
  }

  return (
    <div id='logo-page-container' onClick={e => Navigate(`/login`)}>
      <Circle />
      <Circle />
      <Circle />
      <Circle />
      <Circle />
      <Logo />
    </div >
  )
}

export const Logo = ({ classname, size }) => (
  <div id='logo-page-content' className={classname}>
    <img src={logoIcon} alt="brise" style={{ height: size?.img || '128px' }} />
    <h2 style={{ fontSize: size?.brandName || '6rem' }}>PayBrise</h2>
  </div>
)

export default LogoPage;