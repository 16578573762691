import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";

const options = ["Profile details", "Transactions", "Secret Key And API Key"]
const MerchantProfilePages = () => {
    const Navigate = useNavigate()
    const [selected, setSelected] = useState(options?.find(i => window.location.pathname.includes(i.toLowerCase().replace(/ /g, '-'))))
    const onClick = i => {
        const path = window.location.pathname?.split('/')?.filter(i => i && !options?.find(j => j?.toLowerCase().replace(/ /g, '-') === i))?.join('/')
        Navigate(`/${path}/${i?.toLowerCase().replace(/ /g, '-')}`)
        setSelected(i)
    }

    if(!selected) return <></>;

    return (
        <div id='setting-options'>
            <div className="linear-options settings">
                {options?.map(i => <button
                    className={selected === i ? 'selected' : ''}
                    style={{ cursor: 'pointer' }}
                    onClick={() => onClick(i)}
                >{i}</button>)}
            </div>
        </div>
    )
}

export default MerchantProfilePages