import React, { useState } from 'react'
import { Logo } from '../pages'
import helpIcon from '../assets/icons/help.svg'
import profilePicture from '../assets/images/profile-picture.png'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const Header = () => {
    const [showMenu, setShowMenu] = useState(false);
    const navigate = useNavigate();
    const { auth } = useSelector(state => state);
    return (
        <div id='action-panel-header'>
                <Logo classname={'brand-logo'} size={{ img: '48px', brandName: '26px' }} />
                <div>
                    
                    <div>
                        <span>{auth.user?.email}</span>
                        <div className='profile-pricture-wrapper'>
                            <img src={auth?.avatar || profilePicture} alt="user-profile" width={50} height={50} />
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Header