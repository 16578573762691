import React from 'react'
import { Header, LeftPanel, MerchantProfilePages, SettingOptions } from '../components/index'

const Foundation = ({ Layout }) => {
    const isSetting = window.location.pathname.includes('/setting')
    const isMerchant = window.location.pathname.includes('/merchant')
    return (
        <>
            <Header />
            <div class="grid-container">
                <LeftPanel />
                {isSetting ?
                    <div id="settings">
                        <SettingOptions />
                        <Layout />
                    </div>
                    : isMerchant ?
                        <div id="settings">
                            <MerchantProfilePages />
                            <Layout />
                        </div>
                        :
                        <Layout />
                }
            </div>
        </>
    )
}

export default Foundation