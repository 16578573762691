import React, { useEffect, useState } from 'react'
import { Cards, Pagination, Options, TableFilters } from '../components/index'
import { Foundation } from '../layouts/index'
import threedots from '../assets/icons/threedots.svg'
import nodata from '../assets/images/nodata.png'
import { useNavigate } from 'react-router-dom'
import httpClient from '../components/httpClient'
import DataTable from 'react-data-table-component'
import supportImage from '../assets/icons/vector.svg'
import { errorMessage, successMessage } from '../store/slices/messageSlice'
import { useDispatch } from 'react-redux'


const cardsData = {}
const options = ["All", "Open Tickets", "Closed tickets"]
const MerchantPages = ({ selected, setSelected }) => {
    return (
        <div id='setting-options'>
            <div className="linear-options settings">
                {options?.map(i => <button
                    className={selected === options.indexOf(i) ? 'selected' : ''}
                    style={{ cursor: 'pointer' }}
                    onClick={() => (options.indexOf(i) <= 2) && setSelected(options.indexOf(i))}
                >{i}</button>)}
            </div>
        </div>
    )
}

const Layout = () => {

    const [activePopup, setActivePopup] = useState(null)
    // const [activeDropdown, setActiveDropdown] = useState(null)
    const [selected, setSelected] = useState(0)
    const [merchants, setTickets] = useState();
    const [filterInput, setFilterInput] = useState("");
    const [support, setSupport] = useState();
    const handleFilterChange = e => {
        const value = e.target.value || undefined;
        setFilterInput(value);
    };

    const loadTicketData = async () => {
        const result = await httpClient.getApi('/admin/support');
        setTickets(result.data.data);
    };

    useEffect(() => {

        const loadSupportOverview = async () => {
            const response = await httpClient.getApi('/admin/support/overview');

            if (response.data.data) {
                let totalTicketCount = 0;
                let openTicketCount = 0;
                let closedTicketCount = 0;
                response.data.data.forEach((e) => {
                    if (e._id === 'open') openTicketCount += e.count || 0;
                    if (e._id === 'closed') closedTicketCount += e.count || 0;
                    totalTicketCount += e.count || 0;
                });
                cardsData.type1 = [
                    {
                        title: 'Total Tickets',
                        hideLogo: true,
                        right_text: { value: totalTicketCount },
                    },
                    {
                        title: 'Open Tickets',
                        hideLogo: true,
                        right_text: { value: openTicketCount },
                    },
                    {
                        title: 'Closed Tickets',
                        hideLogo: true,
                        right_text: { value: closedTicketCount },
                    }
                ];
            }
            setSupport(response.data.data);

        }
        loadSupportOverview();
        loadTicketData();
    }, []);

    const openPopup = (orderId) => {
        setActivePopup(orderId);
    }



    const filterData = () => {

        const pageFilter = (type) => {
            if (!merchants) return [];
            return merchants.filter((e) => {
                if (selected === 0) return true;
                if (selected === 1) return e.status === 'open';
                if (selected === 2) return e.status === 'closed';
                return !e.user.isVerified;
            })
        }

        const pageFilterOutput = pageFilter();

        if (filterInput && filterInput !== "") {
            return pageFilterOutput.filter((e) => {
                if (e.orderId) {
                    return (e.orderId).trim().toLowerCase().includes(filterInput.toLowerCase())
                }
                return false;
            });
        }

        return pageFilterOutput;
    }

    if (!merchants) {
        return (
            <>
                <b>
                    Please Wait...
                </b>
            </>
        )
    }

    const convertDate = (inputFormat) => {
        const montsh = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), montsh[pad(d.getMonth())], d.getFullYear()].join(' ')
    }

    return (
        <div style={{ background: "#EFF3F9", padding: "15px" }}>
            <div id="your-business">
                <Cards heading={'Support Ticket Overview'} cardsData={cardsData} showFilters={false} />
            </div>
            <table style={{ width: '100%' }}>
                <tr>
                    <td>
                        <MerchantPages selected={selected} setSelected={setSelected} />
                    </td>
                    <td>
                        <div id='user-auth-form'>
                            <input
                                value={filterInput}
                                onChange={handleFilterChange}
                                placeholder={"Search Order Id"}
                            />
                        </div>
                    </td>
                </tr>
            </table>


            <div id="table">
                <div>
                    {/* <TableFilters TransactionFilters={TransactionFilters} options={dropDownOptions} labels={{ label: "Name", timespan: "Past 30 days" }} />
                    <div className='line l1' />
                    <div className='line l2' /> */}
                    <DataTable
                        title='Support Tickets'
                        pagination
                        noDataComponent={(
                            <>
                                <div>
                                    <b>
                                        No Support Tickets found......
                                    </b>
                                </div>
                            </>
                        )}
                        onRowClicked={row => {

                        }}
                        columns={[
                            {

                                name: 'OrderId',
                                selector: row => (`${row.orderId}`),
                                sortable: true,
                            },
                            {
                                name: 'Title',
                                selector: row => (`${row.title}`),
                                sortable: true,
                            },
                            {
                                name: 'Ticket Created',
                                selector: row => convertDate(row.createdAt)
                            },
                            {
                                name: 'Status',
                                selector: row => {
                                    return (
                                        <div className={`table-btn ${row.status === 'open' ? 'verified' : 'fail'}`}>
                                            <button>{row.status === 'open' ? 'Open' : 'Closed'}</button>
                                        </div>
                                    );
                                },
                            },
                            {
                                name: 'Support',
                                selector: row => (
                                    <div onClick={e => openPopup(row.orderId)}>
                                        <img src={supportImage} alt="support" width={32} height={32} />
                                    </div>
                                )
                            }
                        ]}
                        data={filterData(selected)}
                    />
                    {activePopup && <TicketInfoPopup loadTicketData={loadTicketData} orderId={activePopup} setActivePopup={setActivePopup} />}
                </div>
            </div>
        </div >
    )
}

const TicketInfoPopup = ({ orderId , loadTicketData, basicinformation, setActivePopup, whiteHeader = true, setInfoUpdate }) => {
    const [ticket, setTicket] = useState();
    const [ticketExists, setTicketExists] = useState();
    const [title, setTitle] = useState();
    const [reply, setReply] = useState();
    const [description, setDescription] = useState();
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState({
        titleError: '',
        descError: '',
    });
    const dispatch = useDispatch();


    useEffect(() => {
        const checkTicket = async () => {
            try {
                setLoading(true);
                const response = await httpClient.getApi(`/support/${orderId}`);
                setLoading(false);
                if (response.data.data) {
                    setTicketExists(true);
                    setTicket(response.data.data);
                    setTitle(response.data.data.title);
                    setDescription(response.data.data.description);
                }
                setTicket(false);
            } catch (e) {
                setLoading(false);
                setActivePopup();
            }
        }
        checkTicket();
    }, []);

    const handleSubmit = () => {

        if (title === null) {
            setError({ titleError: 'Title is required' });
            return;
        }

        if (description === null) {
            setError({ descError: 'Description is required' });
            return;
        }

        const dataInput = {
            orderId,
            reply,
        }

        updateSupportTicket(dataInput);
    }

    const updateSupportTicket = async (inputData) => {
        try {
            setLoading(true);
            await httpClient.patchApi(`/admin/support/${orderId}`, inputData);
            dispatch(successMessage('Ticket details updated and marked as closed!!!'));
            loadTicketData();
            setActivePopup();
            setLoading(false);
        } catch (e) {
            dispatch(successMessage('Failed to update ticket details!!!'));
            console.log(e);
            setLoading(false);
        }
    }

    const form = (
        <div className='default-theme-input'>
            <label htmlFor="ticket-title">Subject</label>
            <div id='ticket-title'>
                <b>{title}</b>
            </div><br />

            <label htmlFor="ticket-description">Description</label>
            <div id='ticket-description'>
                <b>{description}</b>
            </div>
            <br />
            <label htmlFor="ticket-reply">Reply</label>
            <textarea cols='50' rows='10' id='ticket-reply' onChange={(e) => setReply(e.target.value)} value={reply} style={{ fontWeight: 'bold' }} />
            {error.replyError && <span className='text-danger'>{error.replyError}</span>}
        </div>
    );

    return (
        <div className='popup-wrapper'>
            <div className='popup-container'>
                <div className={`popup-head ${whiteHeader ? 'white' : ''}`}>Update Support Ticket</div>
                <div className='popup-content'>
                    <div style={{ fontWeight: 'bold' }} id="upload-contract">
                        {form}
                    </div>
                </div>
                <div className='popup-btns'>
                    <button className='default-theme-btn upper' onClick={() => setActivePopup()}>Cancel</button>
                    <button className='default-theme-btn upper' onClick={() => handleSubmit()}>
                        {loading ? 'Please Wait...' : ticketExists ? 'Close Ticket' : 'Create'}
                    </button>
                </div>
            </div>
        </div>
    )
}

const SupportTicket = () => <Foundation Layout={Layout} />
export default SupportTicket