import { Cards, Pagination } from '../components/index'

import { Foundation } from '../layouts/index'
import React from 'react'
import { TableFilters } from '../components/index'

const tableData = [
    {
        "Date": "21 July 2022",
        "TXN Hash": "Abchash",
        "Amount": "0000087",
        "Commission amount": "0000087",
        "Merchant": "Abchash",
        "status": "Successful",
    },
    {
        "Date": "21 July 2022",
        "TXN Hash": "Abchash",
        "Amount": "0000087",
        "Commission amount": "0000087",
        "Merchant": "Abchash",
        "status": "Successful",
    },
    {
        "Date": "21 July 2022",
        "TXN Hash": "Abchash",
        "Amount": "0000087",
        "Commission amount": "0000087",
        "Merchant": "Abchash",
        "status": "Pending",
    },
    {
        "Date": "21 July 2022",
        "TXN Hash": "Abchash",
        "Amount": "0000087",
        "Commission amount": "0000087",
        "Merchant": "Abchash",
        "status": "Successful",
    },
    {
        "Date": "21 July 2022",
        "TXN Hash": "Abchash",
        "Amount": "0000087",
        "Commission amount": "0000087",
        "Merchant": "Abchash",
        "status": "Fail",
    },
]

const dropDownOptions = {
    label: ["Transactions ID", "Customer Email", "Merchant", "Customer Name", "Phone Number",],
    timespan: ["Today", "Yesterday", "Past 7 days", "Past 30 days", "Custom Range"]
}

const cardsData = {
    type1: [
        {
            title: 'Total Commissions',
            right_text: { value: '993' },
        },
        {
            title: 'Commission Rate',
            right_text: { value: '5.4%' },
        },
        {
            title: 'Commissions Count',
            right_text: { value: '993' },
        },
    ],
}

const TransactionFilters = () => {
    const data = {
        months: ["January", "July", "February", "August", "March", "September", "April", "October", "May", "November", "June", "December"],
        transactionStatus: ["Success", "In Progress", "User Cancelled", "Failed", "Refunded",]
    }

    return (
        <div id='transaction-filters'>
            <h2 id='head'>Filters</h2>
            <h2>Commission Status</h2>
            <div>
                {data.transactionStatus.map((i, n) =>
                    <div>
                        <input type="checkbox" id={`${n}${i}`} />
                        <label htmlFor={`${n}${i}`}>{i}</label>
                    </div>
                )}
            </div>
            <h2>Commission Month</h2>
            <div style={{ flexFlow: 'row wrap' }}>
                {data.months.map((i, n) =>
                    <div style={{ width: '45%' }}>
                        <input type="checkbox" id={`${n}${i}`} />
                        <label htmlFor={`${n}${i}`}>{i}</label>
                    </div>
                )}
            </div>
            <h2>Transaction Amount</h2>
            <div>
                <input type="range" />
            </div>
            <p style={{ textAlign: 'center', paddingBottom: '20px' }}>Rs. 0.00 - Rs. 29,680.00</p>
        </div>
    )
}

const Layout = () => {

    return (
        <div id="transactions">
            <div id="your-business">
                <Cards heading={'Commission Overview'} cardsData={cardsData} />
            </div>

            <div id="table">

                <div>
                    <TableFilters TransactionFilters={TransactionFilters} options={dropDownOptions} labels={{ label: "Transactions ID", timespan: "Past 30 days" }} />
                    <div className='line l1' />
                    <div className='line l2' />

                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <div style={{ display: 'flex', gap: '15px' }}>
                                        <input type="checkbox" />
                                        Date
                                    </div>
                                </th>
                                <th>TXN Hash</th>
                                <th>Amount</th>
                                <th>Commission</th>
                                <th>Merchant</th>
                                <th>status</th>
                            </tr>
                        </thead>

                        <tbody>
                            {tableData.map(i => (
                                <tr>
                                    <td>
                                        <div style={{ display: 'flex', gap: '15px' }}>
                                            <input type="checkbox" />
                                            {i["Date"]}
                                        </div>
                                    </td>
                                    <td>{i["TXN Hash"]}</td>
                                    <td>{i["Amount"]}</td>
                                    <td>{i["Commission"]}</td>
                                    <td>{i["Merchant"]}</td>
                                    <td>{i["status"]}</td>
                                    <td className={`table-btn ${i["status"]?.toLowerCase()?.replace(' ', '-')}`}>
                                        <button>{i["status"]}</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <Pagination />
            </div>
        </div>
    )
}

const Commission = () => <Foundation Layout={Layout} />
export default Commission