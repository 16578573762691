import React, { useEffect, useState } from 'react'
import { errorMessage, successMessage } from '../../store/slices/messageSlice'

import { Foundation } from '../../layouts/index'
import PAYBRISE_ABI from "../../abi/abi.json"
import { POPUP } from '../../components'
import httpClient from '../../components/httpClient'
import keccak256 from "keccak256";
import profilePicture from '../../assets/images/profile-picture-large.png'
import useConnection from '../../context';
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

const BusinessInfoContent = ({ setActivePopup, merchant, setApiCallWithUserFetch }) => {
    let userinfo = JSON.parse(localStorage.getItem('jt_user_info'));
    const [businessName, setBusinessName] = useState((merchant.displayName === 'string') ? null : merchant.displayName);
    const [businessType, setBusinessType] = useState((merchant.businessType === 'string') ? null : merchant.businessType);
    const [address, setAddress] = useState((merchant.address === 'string') ? null : merchant.address);
    const [email, setEmail] = useState(merchant.user.email);
    const [loading, setLoading] = useState(false);
    const [verified, setVerified] = useState(merchant.isVerified);
    const [isDisabled, setIsDisabled] = useState(false);
    const [website, setWebsite] = useState((merchant.website === 'string') ? null : merchant.website);


    const dispatch = useDispatch();
    const [error, setError] = useState({
        businessNameError: '',
        businessTypeError: '',
        addressError: '',
        websiteError: '',
        emailError: '',
    });

    const handleSubmit = () => {
        console.log(businessName)
        if (businessName == null) {
            setError({
                businessNameError: 'Business Name is required ',
            });
            return;
        }
        if (businessType == null) {
            setError({
                businessTypeError: 'Business Type is required ',
            });
            return;
        }
        if (address == null) {
            setError({
                addressError: 'Address is required ',
            });
            return;
        }
        if (website == null) {
            setError({
                websiteError: 'Website is required '
            });
            return;
        }
        if (email == null) {
            setError({
                emailError: 'Email is required '
            });
            return;
        }
        const dataInput = {
            displayName: businessName,
            businessType: businessType,
            website: website,
            address: address
        }

        handleSubmitData(dataInput);
    }

    const handleSubmitData = async (inputData) => {

        setIsDisabled(true);
        setLoading(true)


        try {

            const url = `/admin/merchant/${merchant._id}`;
            const response = await httpClient.patchApi(url, inputData);
            setLoading(false);
            setIsDisabled(false);
            dispatch(successMessage('Merchant Details Updated Successfully'))
            setActivePopup();
            setApiCallWithUserFetch(true);

        } catch (e) {
            console.log('e', e)
            dispatch(errorMessage('Something Went Wrong!'))
            setLoading(false);
            setIsDisabled(false);
        }


        setLoading(false);
    }

    return (<>
        <div className='popup-wrapper'>
            <div className='popup-container'>

                <div className={`popup-head white`}>Update Merchant Information</div>
                <div className='popup-content'>
                    <div style={{ fontWeight: 'bold' }} id="upload-contract"></div>
                    <div style={{ fontWeight: 'bold' }}>
                        <div className='default-theme-input'>
                            <label htmlFor="api-label">Display Name</label>
                            <input type="text" id='api-label' style={{ fontWeight: 'bold' }} onChange={(e) => setBusinessName(e.target.value)} value={businessName} />
                            {error.businessNameError && <span className='text-danger'>{error.businessNameError}</span>}
                        </div>
                        <div className='default-theme-input'>
                            <label htmlFor="api-label">Business Type</label>
                            <input type="text" id='api-label' style={{ fontWeight: 'bold' }} onChange={(e) => setBusinessType(e.target.value)} value={businessType} />
                            {error.businessTypeError && <span className='text-danger'>{error.businessTypeError}</span>}
                        </div>
                        <div className='default-theme-input'>
                            <label htmlFor="api-label">Business Address</label>
                            <input type="text" id='api-label' style={{ fontWeight: 'bold' }} onChange={(e) => setAddress(e.target.value)} value={address} />
                            {error.addressError && <span className='text-danger'>{error.addressError}</span>}
                        </div>
                        <div className='default-theme-input'>
                            <label htmlFor="api-label">Business Website </label>
                            <input type="text" id='api-label' style={{ fontWeight: 'bold' }} onChange={(e) => setWebsite(e.target.value)} value={website} />
                            {error.websiteError && <span className='text-danger'>{error.websiteError}</span>}
                        </div>
                        <div className='default-theme-input'>
                            <label htmlFor="email-label">Email </label>
                            <input type="text" id='api-label' style={{ fontWeight: 'bold' }} onChange={(e) => setEmail(e.target.value)} value={email} />
                            {error.emailError && <span className='text-danger'>{error.emailError}</span>}
                        </div>
                        {/* <div className='default-theme-input'>
                            <label htmlFor="api-label">Merchant Status </label>
                            <select onChange={(e) => setVerified(e.target.value)}>
                                <option value='true'>Verified</option>
                                <option value='false'>Non Verified</option>
                            </select>
                        </div> */}
                    </div>
                </div>
                <div className='popup-btns'>
                    <button className='default-theme-btn upper' onClick={handleSubmit} disabled={isDisabled}>{loading ? 'Please Wait' : 'Submit'}</button>
                    <button className='default-theme-btn upper' onClick={() => setActivePopup()}>Cancel</button>
                </div>
            </div>

        </div></>);

}


const Layout = () => {
    const merchantId = useParams().merchant;
    const [activePopup, setActivePopup] = useState(null)
    const [apiCallWithUserFetch, setApiCallWithUserFetch] = useState(false);
    const [merchant, setMerchant] = useState();

    const dispatch = useDispatch();
    const { disconnect, connect, account, library, isActive, contractAddress } = useConnection();
    const [loading, setLoading] = useState(false);
    const [merchantInfo, setMerchantInfo] = useState({});

    const getMerchantInfo = async () => {
        setLoading(true);
        try {
            var contract = new library.eth.Contract(PAYBRISE_ABI, contractAddress);
            let Merchant = await contract.methods.Merchants(keccak256(merchantId)).call();
            setMerchantInfo(Merchant);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    const blockMerchant = async () => {
        setLoading(true);
        try {
            var contract = new library.eth.Contract(PAYBRISE_ABI, contractAddress);
            await contract.methods
                .blockMerchant(keccak256(merchantId))
                .send({ from: account })
                .then(async (result) => {
                    await getMerchantInfo();
                    dispatch(successMessage('commissing changed successfully'))
                    setLoading(false);
                });
        } catch (err) {
            dispatch(errorMessage(err.message))
            setLoading(false);
        }
    }

    const unblockMerchant = async () => {
        setLoading(true);
        try {
            var contract = new library.eth.Contract(PAYBRISE_ABI, contractAddress);
            await contract.methods
                .unblockMerchant(keccak256(merchantId))
                .send({ from: account })
                .then(async (result) => {
                    await getMerchantInfo();
                    dispatch(successMessage('commissing changed successfully'))
                    setLoading(false);
                });
        } catch (err) {
            dispatch(errorMessage(err.message))
            setLoading(false);
        }
    }

    useEffect(() => {
        if (isActive) {
            getMerchantInfo();
        }
    }, [isActive])

    useEffect(() => {
        const loadMerchantData = async () => {
            const response = await httpClient.getApi(`/admin/merchant/${merchantId}`);
            setMerchant(response.data.data);
        }
        loadMerchantData();
    }, []);

    let userinfo = JSON.parse(localStorage.getItem('jt_user_info'));
    const convertDate = (inputFormat) => {
        const montsh = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), montsh[pad(d.getMonth())], d.getFullYear()].join(' ')
    }

    if (!merchant) {
        return (
            <>
                <div>
                    <b>Please wait...</b>
                </div>
            </>
        )
    }

    return (
        <>
            <div id='business-details'>
                <div id='profile'>
                    <div className='profile-pricture-wrapper' style={{ width: '180px', height: '180px' }}>
                        <img src={merchant.user.avatar ? merchant.user.avatar : profilePicture} alt="user-profile" width={164} height={164} />
                    </div>
                    <div id='profile-detail'>
                        <span>{merchant.displayName}</span>
                        <span>{merchant.user.email}</span>
                    </div>
                    {/* <button className="default-theme-btn upper">Upload New</button> */}
                </div>
                <div>
                    <div className='details'>
                        <div className="heading-row">
                            <span className='capital'>Business Information</span>
                            <div>
                                {isActive ?
                                    merchant.isblocked ?
                                        <button
                                            className="default-theme-btn upper"
                                            onClick={e => unblockMerchant()}
                                            disabled={loading}
                                        >
                                            {loading ? "Loading..." : "Unblock"}
                                        </button> :
                                        <button
                                            className="default-theme-btn upper"
                                            onClick={e => blockMerchant()}
                                            disabled={loading}
                                        >
                                            {loading ? "Loading..." : "Block"}
                                        </button>
                                    :
                                    <button
                                        className='default-theme-btn upper'
                                        style={{ marginRight: "10px" }}
                                        onClick={() => connect("metaMask")}
                                    > Connect Wallet
                                    </button>
                                }

                                <button className="default-theme-btn upper" onClick={e => setActivePopup('merchant-business-info')}>Edit Now</button>
                            </div>

                        </div>
                        <div id='detail-data'>
                            <div>
                                <div id="key">Owner Name</div>
                                <div id="value">{merchant.user.firstName} {merchant.user.lastName}</div>
                            </div>
                            <div>
                                <div id="key">Business Name</div>
                                <div id="value">{merchant.displayName}</div>
                            </div>
                            <div>
                                <div id="key">Business Type</div>
                                <div id="value">{merchant.businessType}</div>
                            </div>
                            <div>
                                <div id="key">Country</div>
                                <div id="value">{merchant.user.country}</div>
                            </div>
                            <div>
                                <div id="key">Email Address</div>
                                <div id="value">{merchant.user.email}</div>
                            </div>
                            <div>
                                <div id="key">Date of Registration</div>
                                <div id="value">{convertDate(merchant.user.createdAt)}</div>
                            </div>
                            <div>
                                <div id="key">Verification Status</div>
                                <div id="value" className='table-btn status'><button style={{ background: merchant.isVerified ? 'green' : 'red' }} >{merchant.isVerified ? 'Verified' : 'Non verified'}</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {activePopup === 'merchant-business-info' && <BusinessInfoContent merchant={merchant} setActivePopup={setActivePopup} setApiCallWithUserFetch={setApiCallWithUserFetch} />}
            {activePopup === 'merchant-business-info-12' && <POPUP
                btns={["done", "cancel"]}
                Content={<BusinessInfoContent close={setActivePopup(null)} />}
                heading={"Business Information "}
                close={() => setActivePopup(null)}
            />}
        </>
    )
}

const BusinessDetails = () => <Foundation Layout={Layout} />
export default BusinessDetails