import React, { useEffect, useState } from 'react'
import { Cards, Pagination } from '../components/index'
import support from '../assets/icons/vector.svg'
import { Foundation } from '../layouts/index'
import { TableFilters } from '../components/index'
import httpClient from '../components/httpClient'
import DataTable from 'react-data-table-component'

const TransactionFilter = ({ selected, setSelected, options }) => {
    return (
        <div id='setting-options'>
            <div className="linear-options settings">
                {options?.map(i => <button
                    className={selected === options.indexOf(i) ? 'selected' : ''}
                    style={{ cursor: 'pointer' }}
                    onClick={() => (options.indexOf(i) <= 3) && setSelected(options.indexOf(i))}
                >{i}</button>)}
            </div>
        </div>
    )
}

const Layout = () => {

    const [transactions, setTransactions] = useState();
    const [selected, setSelected] = useState(0);

    const [filterInput, setFilterInput] = useState("");
    let cardData = {};
    useEffect(() => {
        const loadAllTransactions = async () => {
            const response = await httpClient.getApi('/admin/transactions');
            setTransactions(response.data.data);
        }
        loadAllTransactions();
    }, []);

    const convertDate = (inputFormat) => {
        const montsh = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), montsh[pad(d.getMonth())], d.getFullYear()].join(' ')
    }

    if (transactions) {
        let totalSum = 0;
        let successCount = 0;
        let failCount = 0;
        let pendingCount = 0;
        let failSum = 0;
        let successSum = 0;
        let pendingSum = 0;

        transactions?.forEach((e) => {
            if (e.status === 'success') {
                successSum = successSum + parseFloat(e.amount);
                successCount++;
            }
            if (e.status === 'pending') {
                pendingSum = pendingSum + parseFloat(e.amount);
                pendingCount++;
            }
            if (e.status === 'failed') {
                failSum = failSum + parseFloat(e.amount);
                failCount++;
            }
            totalSum += parseFloat(e.amount);
        });

        cardData = {
            successCount,
            failCount,
            pendingCount,
            totalCount: transactions.length,
            type1: [
                {
                    title: 'Transactions',
                    left_text: { value: transactions.length, title: 'Txn Count' },
                    right_text: { value: totalSum, label: '(BRISE)' },
                },
                {
                    title: 'Success Transactions',
                    left_text: { value: successCount, title: 'Txn Count' },
                    right_text: { value: successSum, label: '(BRISE)' },
                },
                {
                    title: 'Failed Transactions',
                    left_text: { value: failCount, title: 'Fail Count' },
                    right_text: { value: failSum, label: '(BRISE)' },
                },
                {
                    title: 'Pending Transactions',
                    left_text: { value: pendingCount, title: 'Fail Count' },
                    right_text: { value: pendingSum, label: '(BRISE)' },
                },
            ],
        }
    }

    const handleFilterChange = e => {
        const value = e.target.value || undefined;
        setFilterInput(value);
    };

    const filterData = () => {
        const pageFilter = () => {
            return transactions.filter((e) => {
                if (selected === 0) return true;
                if (selected === 1) return e.status === 'success';
                if (selected === 2) return e.status === 'failed';
                if (selected === 3) return e.status === 'pending';
            });
        }

        const pageFilterOutput = pageFilter();

        if(filterInput && filterInput !== "") {
            return pageFilterOutput.filter((e) => {
                if(e.txHash) {
                    return e.txHash.includes(filterInput);
                }
                return true;
            });
        }

        return pageFilterOutput;
    }

    if (!transactions) {
        return (
            <>
                <div>
                    <b>Please wait....</b>
                </div>
            </>
        )
    }


    return (
        <div id="transactions">
            <div id="your-business">
                <Cards heading={'Transactions Overview'} cardsData={cardData} showFilters={false} />
            </div>

            <div id="table">
                <div>
                    <table>
                        <tr>
                            <td><TransactionFilter selected={selected} setSelected={setSelected} options={[`All (${cardData.totalCount})`, `Success (${cardData.successCount})`, `Fail (${cardData.failCount})`, `Pending (${cardData.pendingCount})`]} /></td>
                            <td>
                                <div id='user-auth-form'>
                                <input
                                    value={filterInput}
                                    onChange={handleFilterChange}
                                    placeholder={"Search Trasnaction Hash"}
                                />
                                </div>
                            </td>
                        </tr>
                    </table>
                    <div className='line l1' />
                    <div className='line l2' />
                    <DataTable
                        
                        data={filterData()}
                        title='Transactions'
                        pagination
                        noDataComponent={(
                            <>
                                <div>
                                    <b>
                                        No Transactions Found....
                                    </b>
                                </div>
                            </>
                        )}
                        columns={[
                            {
                                name: 'Time',
                                selector: row => convertDate(Date.parse(row['createdAt'])),
                                sortable: true,
                            },
                            {
                                name: 'Transaction Hash',
                                selector: row => row.txHash,
                                sortable: true,
                            },
                            {
                                name: 'Business Name',
                                selector: row => row.merchant.displayName,
                                sortable: true,
                            },
                            {
                                name: 'Amount',
                                selector: row => row.amount,
                                sortable: true,
                            },
                            {
                                name: 'Source',
                                selector: row => row.source,
                                sortable: true,
                            },
                            {
                                name: 'Commission',
                                selector: row => row.adminCut,
                                sortable: true,
                            },
                            {
                                name: 'Status',
                                selector: row => {
                                    return (<div className={`table-btn`} ><button style={{ background: row.status === 'pending' ? '#F6B048' : row.status === 'success' ? 'green' : 'red' }}>{row.status.toUpperCase()}</button></div>)
                                },
                            }
                        ]}

                    />
                    {/* <table>
                        <thead>
                            <tr>
                                <th>
                                    <div style={{ display: 'flex', gap: '15px' }}>
                                        <input type="checkbox" />
                                        Date
                                    </div>
                                </th>
                                <th>Transaction Hash</th>
                                <th>Amount</th>
                                <th>Source</th>
                                <th>Settlement amount</th>
                                <th>Commission</th>
                                <th>Status</th>
                                <th>Support</th>
                            </tr>
                        </thead>

                        <tbody>
                            {transactions.map(i => (
                                <tr>
                                    <td>
                                        <div style={{ display: 'flex', gap: '15px' }}>
                                            <input type="checkbox" />
                                            {convertDate(i["createdAt"])}
                                        </div>
                                    </td>
                                    <td>{i["txHash"]}</td>
                                    <td>{i["amount"]}</td>
                                    <td>{i["source"]}</td>
                                    <td>{i["vendorCut"]}</td>
                                    <td>{i["adminCut"]}</td>
                                    <td className={`table-btn ${i["status"]?.toLowerCase() === 'success' ? 'successful' : ''} ${i["status"]?.toLowerCase() === 'failed' ? 'fail' : ''}`}><button>{i["status"].toUpperCase()}</button></td>
                                    
                                </tr>
                            ))}
                        </tbody>
                    </table> */}
                </div>
                {/* <Pagination /> */}
            </div>
        </div>
    )
}

const Transactions = () => <Foundation Layout={Layout} />
export default Transactions