import React, { useState } from 'react'
import { Foundation } from '../../layouts/index'
import dustbin from '../../assets/icons/dustbin.svg'
import { POPUP, UploadContent } from '../../components'
const tableData = [
    {
        "Date": "12-09-22",
        "Name": "WordPress",
        "Size": "89MB",
    },
    {
        "Date": "12-09-22",
        "Name": "WordPress",
        "Size": "89MB",
    },
    {
        "Date": "12-09-22",
        "Name": "WordPress",
        "Size": "89MB",
    },
    {
        "Date": "12-09-22",
        "Name": "WordPress",
        "Size": "89MB",
    },
    {
        "Date": "12-09-22",
        "Name": "WordPress",
        "Size": "89MB",
    },
]

const Content = () => <UploadContent askDocumentType={true} />

const Layout = () => {
    const [activePopup, setActivePopup] = useState(null)

    return (
        <div id='documents-verification'>
            <div>
                <div id="table">
                    <div className="heading-row">
                        <span className='capital'>Documents File</span>
                    </div>

                    <div className='line l1' />
                    <div className='line l2' />

                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <div style={{ display: 'flex', gap: '15px' }}>
                                        <input type="checkbox" />
                                        Date
                                    </div>
                                </th>
                                <th>Name</th>
                                <th>Size</th>
                                <th></th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody>
                            {tableData.map(i => (
                                <tr>
                                    <td>
                                        <div style={{ display: 'flex', gap: '15px' }}>
                                            <input type="checkbox" />
                                            {i["Date"]}
                                        </div>
                                    </td>
                                    <td style={{ fontWeight: '700' }}>{i["Name"]}</td>
                                    <td style={{ fontWeight: '700' }}>{i["Size"]}</td>
                                    <td className='table-btn default-theme'>
                                        <button style={{ fontWeight: '600', fontSize: '13px' }}>download</button>
                                        <button style={{ fontWeight: '600', fontSize: '13px', marginLeft: "15px" }}>view</button>
                                    </td>
                                    <td><img src={dustbin} alt="delete" width={32} height={32} /></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {activePopup && <POPUP
                btns={["Upload", "cancel"]}
                Content={Content}
                heading={"Upload Contract"}
                close={() => setActivePopup(null)}
            />}
        </div>
    )
}

const DocumentsVerification = () => <Foundation Layout={Layout} />
export default DocumentsVerification