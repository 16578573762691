import React, { useEffect, useState } from 'react'
import { errorMessage, successMessage } from '../../store/slices/messageSlice';

import { Foundation } from '../../layouts/index'
import PAYBRISE_ABI from "../../abi/abi.json"
import TOKEN_ABI from "../../abi/token.json"
import useConnection from '../../context';
import { useDispatch } from 'react-redux';

const Layout = () => {

    const {
        disconnect,
        connect,
        account,
        library,
        isActive,
        contractAddress
    } = useConnection();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const [ownerAddress, setOwnerAddress] = useState("");
    const [baseAmount, setBaseAmount] = useState("");
    const [tokenAddress, setTokenAddress] = useState("");
    const [tokenAmt, setTokenAmt] = useState("");

    const withdrawBaseCurrency = async () => {
        setLoading(true);
        try {
            if (isNaN(parseFloat(baseAmount))) {
                dispatch(errorMessage("enter withdraw"))
                setLoading(false);
                return;
            }

            let amount = parseFloat(baseAmount) * Math.pow(10, 18);
            amount = amount.toLocaleString("fullwide", { useGrouping: false });

            var contract = new library.eth.Contract(PAYBRISE_ABI, contractAddress);
            await contract.methods
                .withdrawBaseCurrency(amount)
                .send({ from: account })
                .then(async (result) => {
                    dispatch(successMessage('withdraw successfully'))
                    setLoading(false);
                });
        } catch (err) {
            dispatch(errorMessage(err.message))
            setLoading(false);
        }
    }

    const changeOwnerShip = async () => {
        setLoading(true);
        try {
            if (ownerAddress === "") {
                dispatch(errorMessage("enter owner address"))
                setLoading(false);
                return;
            }

            var contract = new library.eth.Contract(PAYBRISE_ABI, contractAddress);
            await contract.methods
                .changeOwnerShip(ownerAddress)
                .send({ from: account })
                .then(async (result) => {
                    dispatch(successMessage('OwnerShip successfully'))
                    setLoading(false);
                });
        } catch (err) {
            dispatch(errorMessage(err.message))
            setLoading(false);
        }
    }

    const withdrawToken = async () => {
        setLoading(true);
        try {
            if (isNaN(parseFloat(tokenAmt)) || tokenAddress === "") {
                dispatch(errorMessage("enter tokenAddress or withdraw amount"))
                setLoading(false);
                return;
            }

            var tokenContract = new library.eth.Contract(TOKEN_ABI, tokenAddress);
            let decimals = await tokenContract.methods.decimals().call();

            let amount = parseFloat(tokenAmt) * Math.pow(10, decimals);
            amount = amount.toLocaleString("fullwide", { useGrouping: false });

            var contract = new library.eth.Contract(PAYBRISE_ABI, contractAddress);
            await contract.methods
                .withdrawToken(tokenAddress, amount)
                .send({ from: account })
                .then(async (result) => {
                    dispatch(successMessage('withdraw successfully'))
                    setLoading(false);
                });
        } catch (err) {
            dispatch(errorMessage(err.message))
            setLoading(false);
        }
    }

    const fetchOwner = async () => {
        setLoading(true);
        try {
            var contract = new library.eth.Contract(PAYBRISE_ABI, contractAddress);
            let owner = await contract.methods.owner().call();
            setOwnerAddress(owner);

            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (isActive) {
            fetchOwner();
        }
    }, [isActive])

    return (
        <div id='your-business'>
            <div id="table">
                <div className='heading-row'>
                    <span>Contract Settings</span>
                    <div>
                        {isActive ?
                            <button
                                className='default-theme-btn'
                                style={{ marginRight: "10px" }}
                                onClick={() => disconnect()}
                            >
                                Disconnect
                            </button>
                            :
                            <button
                                className='default-theme-btn'
                                style={{ marginRight: "10px" }}
                                onClick={() => connect("metaMask")}
                            > Connect Wallet
                            </button>
                        }
                    </div>
                </div>

                <div className='default-theme-input change-password-wrapper blur-inputs'>
                    <label htmlFor="current-password">
                        <span>change OwnerShip</span>
                    </label>
                    <input
                        type={"text"}
                        placeholder="enter address"
                        className='form-control'
                        disabled={loading}
                        value={ownerAddress}
                        onChange={(e) => setOwnerAddress(e.target.value)}
                    />

                    {isActive ?
                        <button
                            className='default-theme-btn'
                            disabled={loading}
                            onClick={() => changeOwnerShip()}
                        >
                            {loading ? "Loading..." : "Change"}
                        </button> :
                        <button
                            className='default-theme-btn'
                            style={{ marginRight: "10px" }}
                            onClick={() => connect("metaMask")}
                        > Connect Wallet
                        </button>
                    }

                </div>
                <div className='default-theme-input change-password-wrapper blur-inputs'>
                    <label htmlFor="current-password">
                        <span>withdraw BaseCurrency</span>
                    </label>
                    <input
                        type={"text"}
                        placeholder="amount"
                        className='form-control'
                        disabled={loading}
                        onChange={(e) => setBaseAmount(e.target.value)}
                    />
                    {isActive ?
                        <button
                            className='default-theme-btn'
                            onClick={() => withdrawBaseCurrency()}
                            disabled={loading}
                        >
                            {loading ? "Loading..." : "withdraw"}
                        </button> :
                        <button
                            className='default-theme-btn'
                            style={{ marginRight: "10px" }}
                            onClick={() => connect("metaMask")}
                        > Connect Wallet
                        </button>
                    }

                </div>
                <div className='default-theme-input change-password-wrapper blur-inputs'>
                    <label htmlFor="current-password">
                        <span>withdraw Token</span>
                    </label>
                    <input
                        type={"text"}
                        placeholder="enter token address"
                        className='form-control'
                        disabled={loading}
                        onChange={(e) => setTokenAddress(e.target.value)}
                    />
                    <input
                        type={"text"}
                        placeholder="amount"
                        className='form-control'
                        disabled={loading}
                        onChange={(e) => setTokenAmt(e.target.value)}
                    />
                    {isActive ?
                        <button
                            className='default-theme-btn'
                            onClick={() => withdrawToken()}
                            disabled={loading}
                        >
                            {loading ? "Loading..." : "withdraw"}
                        </button> :
                        <button
                            className='default-theme-btn'
                            style={{ marginRight: "10px" }}
                            onClick={() => connect("metaMask")}
                        > Connect Wallet
                        </button>
                    }

                </div>
            </div>
        </div>
    )
}

const ContractSetting = () => <Foundation Layout={Layout} />
export default ContractSetting