import React, { useState } from 'react'
import { Cards, Options, Pagination, POPUP } from '../components/index'
import { Foundation } from '../layouts/index'
import { TableFilters } from '../components/index'
import threedots from '../assets/icons/threedots.svg'
import merchant1 from "../assets/images/merchants/merchant1.png";
import merchant2 from "../assets/images/merchants/merchant2.png";
import merchant3 from "../assets/images/merchants/merchant3.png";
import merchant4 from "../assets/images/merchants/merchant4.png";
import merchant5 from "../assets/images/merchants/merchant5.png";

const merchantProfiles = { merchant1, merchant2, merchant3, merchant4, merchant5 }
const tableData = [
    {
        "Name Description": "Meet your cash flow needs...",
        "Page Location": "Redirection page",
        "Start Date": "21 July 2022",
        "End Date": "29 July 2022",
        "status": "Active",
    },
    {
        "Name Description": "Meet your cash flow needs...",
        "Page Location": "Redirection page",
        "Start Date": "21 July 2022",
        "End Date": "29 July 2022",
        "status": "Active",
    },
    {
        "Name Description": "Meet your cash flow needs...",
        "Page Location": "Redirection page",
        "Start Date": "21 July 2022",
        "End Date": "29 July 2022",
        "status": "Active",
    },
    {
        "Name Description": "Meet your cash flow needs...",
        "Page Location": "Redirection page",
        "Start Date": "21 July 2022",
        "End Date": "29 July 2022",
        "status": "Expired",
    },
    {
        "Name Description": "Meet your cash flow needs...",
        "Page Location": "Redirection page",
        "Start Date": "21 July 2022",
        "End Date": "29 July 2022",
        "status": "Active",
    },
]

const dropDownOptions = {
    label: ["Merchant Dashboard", "Redirection page"],
    timespan: ["Today", "Yesterday", "Past 7 days", "Past 30 days", "Custom Range"]
}

const cardsData = {
    type1: [
        {
            title: 'Active Advertisements',
            hideLogo: true,
            right_text: { value: '20' },
        },
        {
            title: 'Inactive Advertisements',
            hideLogo: true,
            right_text: { value: '06' },
        },
        {
            title: 'Merchant Dashboard Adds',
            hideLogo: true,
            right_text: { value: '13' },
        },
        {
            title: 'Redirection page Adds',
            hideLogo: true,
            right_text: { value: '07' },
        },
    ],
}

const CreateAdvertisementContent = () => (
    <div style={{ fontWeight: 'bold' }}>
        <div className='default-theme-input' style={{ width: '30vw' }}>
            <label htmlFor="api-label">Name</label>
            <input type="text" id='api-label' style={{ fontWeight: 'bold' }} defaultValue={"ABCD Corporation"} />
        </div>
        <div className='default-theme-input' style={{ width: '30vw' }}>
            <label htmlFor="api-label">Page Location</label>
            <input type="text" id='api-label' style={{ fontWeight: 'bold' }} defaultValue={"Redirection page"} />
        </div>
        <div className='default-theme-input' style={{ width: '30vw' }}>
            <label htmlFor="api-label">Start Date</label>
            <input type="text" id='api-label' style={{ fontWeight: 'bold' }} defaultValue={"21 July 2022"} />
        </div>
        <div className='default-theme-input' style={{ width: '30vw' }}>
            <label htmlFor="api-label">End Date</label>
            <input type="text" id='api-label' style={{ fontWeight: 'bold' }} defaultValue={"21 July 2022"} />
        </div>
        <div className='default-theme-input' style={{ width: '30vw' }}>
            <label htmlFor="api-label" style={{ position: "relative" }}>
                Image upload
                <span style={{ position: "absolute", right: "26px", top: "42px", translate: "0 -35%", fontSize: "15px" }} className='brise-theme-font'>Browse</span>
            </label>
            <input type="text" id='api-label' style={{ fontWeight: 'bold' }} disabled />
        </div>
    </div>
)

const TransactionFilters = () => {
    const data = {
        months: ["January", "July", "February", "August", "March", "September", "April", "October", "May", "November", "June", "December"],
        transactionStatus: ["Success", "In Progress", "User Cancelled", "Failed", "Refunded",]
    }

    return (
        <div id='transaction-filters'>
            <h2 id='head'>Filters</h2>
            <h2>Commission Status</h2>
            <div>
                {data.transactionStatus.map((i, n) =>
                    <div>
                        <input type="checkbox" id={`${n}${i}`} />
                        <label htmlFor={`${n}${i}`}>{i}</label>
                    </div>
                )}
            </div>
            <h2>Commission Month</h2>
            <div style={{ flexFlow: 'row wrap' }}>
                {data.months.map((i, n) =>
                    <div style={{ width: '45%' }}>
                        <input type="checkbox" id={`${n}${i}`} />
                        <label htmlFor={`${n}${i}`}>{i}</label>
                    </div>
                )}
            </div>
            <h2>Transaction Amount</h2>
            <div>
                <input type="range" />
            </div>
            <p style={{ textAlign: 'center', paddingBottom: '20px' }}>Rs. 0.00 - Rs. 29,680.00</p>
        </div>
    )
}

const Layout = () => {
    const [activePopup, setActivePopup] = useState(null)
    const [activeDropdown, setActiveDropdown] = useState(null)
    return (
        <div id="transactions">
            <div id="your-business">
                <Cards
                    heading={'Advertisements Overview'}
                    cardsData={cardsData}
                    rightButton={{ label: "+ Create new Advertisements", onClick: () => setActivePopup(true) }}
                />
            </div>

            <div id="table">

                <div>
                    <TableFilters TransactionFilters={TransactionFilters} options={dropDownOptions} labels={{ label: "Merchant Dashboard", timespan: "Past 30 days" }} />
                    <div className='line l1' />
                    <div className='line l2' />

                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <div style={{ display: 'flex', gap: '15px' }}>
                                        <input type="checkbox" />
                                        Image
                                    </div>
                                </th>
                                <th>Name Description</th>
                                <th>Page Location</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>status</th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody>
                            {tableData.map((i, n) => (
                                <tr>
                                    <td>
                                        <div style={{ display: 'flex', gap: '15px' }}>
                                            <input type="checkbox" />
                                            <img src={merchantProfiles?.[`merchant${n + 1}`]} alt={`merchant${n + 1}`} width={45} />
                                        </div>
                                    </td>
                                    <td>{i["Name Description"]}</td>
                                    <td>{i["Page Location"]}</td>
                                    <td>{i["Start Date"]}</td>
                                    <td>{i["End Date"]}</td>
                                    <td className={`table-btn ${i["status"]?.toLowerCase()?.replace(' ', '-')}`}>
                                        <button>{i["status"]}</button>
                                    </td>
                                    <td style={{ position: 'relative' }}>
                                        <img src={threedots} alt='options' width={25} height={25} onClick={e => setActiveDropdown(d => d === n ? null : n)} />
                                        {activeDropdown === n && <Options data={["Delete", "Edit", "Activate"]} style={{ top: "15px", width: "auto", left: "-140px" }} showActiveSwitch={true} />}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <Pagination />
            </div>
            {activePopup && <POPUP
                btns={["done", "cancel"]}
                Content={CreateAdvertisementContent}
                heading={"Contact information "}
                close={() => setActivePopup(null)}
            />}
        </div>
    )
}

const Advertisements = () => <Foundation Layout={Layout} />
export default Advertisements