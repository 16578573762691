import React from 'react'
import { BarLine, Pagination } from '../../components/index'
import { Foundation } from '../../layouts/index'
import { TableFilters } from '../../components/index'
import dropdown from '../../assets/icons/dropdown.svg'

const tableData = [
    {
        "Date": "21 July 2022",
        "Transaction Hash": "Abchash",
        "Amount": "0000087",
        "Status": "Successful",
    },
    {
        "Date": "21 July 2022",
        "Transaction Hash": "Abchash",
        "Amount": "0000087",
        "Status": "Fail",
    },
    {
        "Date": "21 July 2022",
        "Transaction Hash": "Abchash",
        "Amount": "0000087",
        "Status": "Fail",
    },
    {
        "Date": "21 July 2022",
        "Transaction Hash": "Abchash",
        "Amount": "0000087",
        "Status": "Successful",
    },
    {
        "Date": "21 July 2022",
        "Transaction Hash": "Abchash",
        "Amount": "0000087",
        "Status": "Pending",
    },
]

const Layout = () => (
    <div id="merchant-transactions" className='total-payment-recieved'>
        <div id='merchant-transctions-container'>
            <div id='head'>
                <div style={{ padding: "15px", margin: "10px 0 0", display: "flex", justifyContent: "space-between" }}>
                    <h2 style={{ fontSize: "18px" }}>Total Commission Paid</h2>
                    <div id='date-selector' className='dropdown'>
                        <div className='dropdown-select'>Past 7 days <img src={dropdown} alt="dropdown" /></div>
                    </div>
                </div>
                <div id='chart-wrapper'>
                    <div id='chart-container'>
                        <div id='chart' style={{ width: "600px" }}>
                            <BarLine options={{
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                },
                                scales: {
                                    y: {
                                        max: 160,
                                        title: {
                                            text: "Total Counts",
                                            display: true,
                                            padding: 10
                                        }
                                    }
                                }
                            }} />
                        </div>
                        <div id='chart-brise-scale'>
                            <div>
                                <div>0000400 Brise</div>
                                <div>0000300 Brise</div>
                                <div>0000200 Brise</div>
                                <div>0000100 Brise</div>
                                <div>0 Brise</div>
                            </div>
                            <small>Total Received</small>
                        </div>
                    </div>
                    <div id='list'>
                        <div>
                            <div>
                                <div className='color-circle green' />
                                <span>Total Amount</span>
                            </div>
                            <b>00000456 BRISE</b>
                        </div>
                        <div>
                            <div>
                                <div className='color-circle red' />
                                <span>Total Count</span>
                            </div>
                            <b>22</b>
                        </div>
                    </div>
                </div>
            </div>
            <h3 style={{ margin: "20px 0" }}>Recent Commission Paid</h3>
            <div id="transactions" style={{ padding: "0" }}>
                <div id="table">
                    <div>
                        <TableFilters />
                        <div className='line l1' />
                        <div className='line l2' />

                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <div style={{ display: 'flex', gap: '15px' }}>
                                            <input type="checkbox" />
                                            Date
                                        </div>
                                    </th>
                                    <th>Transaction Hash</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                </tr>
                            </thead>

                            <tbody>
                                {tableData.map(i => (
                                    <tr>
                                        <td>
                                            <div style={{ display: 'flex', gap: '15px' }}>
                                                <input type="checkbox" />
                                                {i["Date"]}
                                            </div>
                                        </td>
                                        <td>{i["Transaction Hash"]}</td>
                                        <td>{i["Amount"]}</td>
                                        <td className={`table-btn ${i["Status"]?.toLowerCase()?.replace(' ', '-')}`}><button>{i["Status"]}</button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <Pagination />
                </div>
            </div>
        </div>
    </div>
)

const TotalCommissionPaid = () => <Foundation Layout={Layout} />
export default TotalCommissionPaid;