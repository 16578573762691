import React from 'react'
import logo from '../assets/icons/logo.svg'
import dropdown from '../assets/icons/dropdown.svg'
import calender from '../assets/icons/calender.svg'
import archieve from '../assets/icons/archieve.svg'

const defaultCardData = {
    type1: [
        {
            title: 'Transactions',
            left_text: { value: '120', title: 'Txn Count' },
            right_text: { value: '993', label: '(BRISE)' },
        },
        {
            title: 'Transactions2',
            left_text: { value: '120', title: 'Txn Count' },
            right_text: { value: '993', label: '(BRISE)' },
        },
        {
            title: 'Settlements',
            left_text: { value: '10', title: 'Settlement Count' },
            right_text: { value: '993', label: '(BRISE)' },
        },
        {
            title: 'Fail Transactions',
            left_text: { value: '90', title: 'Fail Count' },
            right_text: { value: '993', label: '(BRISE)' },
        },
    ]
}

const Cards = ({ heading, showFilters, cardsData = defaultCardData, showType2, rightButton }) => {
    return (
        <>
            <div id='head'>
                <h2>{heading}</h2>
                {showFilters &&
                    <div>
                        <div id='date-selector' className='dropdown'>
                            <div className='dropdown-select'>Past 7 days <img src={dropdown} alt="dropdown" /></div>
                            <div id='calender'><img src={calender} alt="calender" /></div>
                        </div>
                        <div id='archieve'><img src={archieve} alt="archieve" /></div>
                    </div>
                }
                {rightButton &&
                    <div>
                        <button className='default-theme-btn' onClick={rightButton?.onClick}>{rightButton?.label}</button>
                    </div>
                }
            </div>

            <div className='head-cards-container card-type-1'>
                {cardsData?.type1?.map(({ Logo, ...i }, n, arr) => (
                    <div className='head-card' style={{ width: `${parseInt(100 / arr.length)}%` }}>
                        <p>
                            {!i?.hideLogo && (Logo ? <Logo fill={'#FFF'} /> : <div><img src={logo} alt="BRISE" width={18} height={18} /></div>)}
                            <span>{i?.title}</span>
                        </p>
                        <div className='card-value-wrapper'>
                            <div>
                                <p style={{ fontWeight: '600' }}>{i?.left_text?.value}</p>
                                <p style={{ fontSize: '10px' }}>{i?.left_text?.title}</p>
                            </div>
                            <p><span style={{ fontSize: '24px', fontWeight: '600' }}>{i?.right_text?.value}</span> {i?.right_text?.label}</p>
                        </div>
                    </div>
                ))}
            </div>

            {showType2 && <div className='head-cards-container card-type-2'>
                {cardsData?.type2?.map(({ Logo, ...i }, n, arr) => (
                    <div className='head-card' style={{ width: `${parseInt(100 / arr.length)}%` }}>
                        <p>
                            {!i?.hideLogo && (Logo ? <Logo fill={'#FFF'} /> : <div><img src={logo} alt="BRISE" width={18} height={18} /></div>)}
                            <p>{i?.title}</p>
                        </p>
                        <div className='card-value-wrapper'>
                            {i?.values?.map(v => (
                                <div>
                                    <p>{v?.value}</p>
                                    <small>{v?.title}</small>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            }
        </>
    )
}

export default Cards