import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { ReactComponent as Dashboard } from '../assets/icons/monitor.svg'
import { ReactComponent as Transactions } from '../assets/icons/creditcard.svg'
import { ReactComponent as Setting } from '../assets/icons/nut.svg'
import { ReactComponent as Merchant } from '../assets/icons/store.svg'
import { ReactComponent as Commission } from '../assets/icons/commission.svg'
import { ReactComponent as Advertisements } from '../assets/icons/megaphone.svg'
import { ReactComponent as Ticket } from '../assets/icons/ticket.svg'

// const labels = ["Dashboard", "Merchant", "Transactions", "Commission", "Advertisements", "Setting", "Support Ticket", "Logout"]
const labels = ["Dashboard", "Merchant", "Transactions", "Setting", "Support Ticket", "Logout"]

const Icon = ({ label, fill }) => {
    switch (label) {
        case 'Dashboard': return <Dashboard fill={fill} />
        case 'Merchant': return <Merchant fill={fill} />
        case 'Transactions': return <Transactions fill={fill} />
        case 'Commission': return <Commission fill={fill} />
        case 'Advertisements': return <Advertisements fill={fill} />
        case 'Setting': return <Setting fill={fill} />
        case 'Support Ticket': return <Ticket fill={fill} />
        default: break;
    }
}

const LeftPanel = () => {
    const Navigate = useNavigate()
    const [selected, setSelected] = useState(labels?.find(i =>
        window.location.pathname.split("/")?.filter(i => i)?.[0] === i.toLowerCase()?.replace(" ", "-")
    ))
    const onClick = l => {
        if(l.toLowerCase()?.replace(" ", "-") === 'logout') {
            localStorage.clear();
            return Navigate('/login');
        }
        setSelected(l)
        Navigate(`/${l.toLowerCase()?.replace(" ", "-")}`)
    }
    return (
        <div id='action-panel-leftside'>
            {labels?.map(l => (
                <div className={selected === l ? 'selected' : ''} style={{ cursor: 'pointer' }} onClick={e => onClick(l)}>
                    <Icon label={l} fill={selected === l ? 'white' : 'url(#brise-theme)'} />
                    <span>{l}</span>
                </div>
            ))}
        </div>
    )
}

export default LeftPanel