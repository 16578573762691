import React, { useEffect, useState } from 'react'
import { errorMessage, successMessage } from '../../store/slices/messageSlice';

import { Foundation } from '../../layouts/index'
import PAYBRISE_ABI from "../../abi/abi.json"
import useConnection from '../../context';
import { useDispatch } from 'react-redux';

const Layout = () => {

    const {
        disconnect,
        connect,
        account,
        library,
        isActive,
        contractAddress
    } = useConnection();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [commissionRate, setCommissionRate] = useState("");

    const changeFees = async () => {
        setLoading(true);
        try {
            if (isNaN(parseFloat(commissionRate))) {
                dispatch(errorMessage("enter commission Rate"))
                setLoading(false);
                return;
            }

            var contract = new library.eth.Contract(PAYBRISE_ABI, contractAddress);
            await contract.methods
                .changeFees(commissionRate)
                .send({ from: account })
                .then(async (result) => {
                    dispatch(successMessage('commissing changed successfully'))
                    setLoading(false);
                });
        } catch (err) {
            dispatch(errorMessage(err.message))
            setLoading(false);
        }
    }

    const fetchOwner = async () => {
        setLoading(true);
        try {
            var contract = new library.eth.Contract(PAYBRISE_ABI, contractAddress);
            let adminFee = await contract.methods.adminFee().call();
            setCommissionRate(adminFee);

            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (isActive) {
            fetchOwner();
        }
    }, [isActive])

    return (
        <div>
            <div id="table">
               
                <div className='default-theme-input change-password-wrapper blur-inputs'>
                    <label htmlFor="current-password">
                        <span>change Commission Rate</span>
                    </label>
                    <input
                        type={"text"}
                        placeholder="enter commission rate"
                        className='form-control'
                        disabled={loading}
                        value={commissionRate}
                        onChange={(e) => setCommissionRate(e.target.value)}
                    />

                    {isActive ?
                        <button
                            className='default-theme-btn'
                            disabled={loading}
                            onClick={() => changeFees()}
                        >
                            {loading ? "Loading..." : "Change"}
                        </button> :
                        <button
                            className='default-theme-btn'
                            style={{ marginRight: "10px" }}
                            onClick={() => connect("metaMask")}
                        > Connect Wallet
                        </button>
                    }

                </div>
            </div>
        </div>
    )
}

const CommissionRate = () => <Foundation Layout={Layout} />
export default CommissionRate