import React, { useState } from 'react'
import dropdown from '../assets/icons/dropdown.svg'
import searchIcon from '../assets/icons/search.svg'
import calender from '../assets/icons/calender.svg'
import archieve from '../assets/icons/archieve.svg'
import sliders from '../assets/icons/sliders.svg'
import { Options, POPUP } from '../components/index'

const dropdownOptions = {
    label: ["Transactions hash", "Amount", "Source", "Status"],
    timespan: ["Today", "Yesterday", "Past 7 days", "Past 30 days", "Custom Range"]
}

const TableFilters = ({
    TransactionFilters,
    options = dropdownOptions,
    labels = { label: "Transactions hash", timespan: "Past 7 days" },
    rightSideElements = ["panel", "searchbar", "download"]
}) => {

    const [activeDropdown, setActiveDropdown] = useState(null);
    const [activePopup, setActivePopup] = useState(null);
    const Content = () => (
        <>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <input type="radio" name='export-file-type' id="to-pdf" defaultChecked />
                <label htmlFor="to-pdf" style={{ fontWeight: 'bolder' }}>Export To PDF</label>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <input type="radio" name='export-file-type' id='to-excel' />
                <label htmlFor="to-excel" style={{ fontWeight: 'bolder' }}>Export To EXCEL</label>
            </div>
        </>
    )

    return (
        <>
            <div id='options'>
                <div style={{ display: 'flex', gap: '10px' }}>
                    {options?.label?.[0] && <div className='dropdown'>
                        <div
                            id='dropdown-label'
                            className='dropdown-select'
                            onClick={e => setActiveDropdown(i => i === e.target.id ? null : e.target.id)}
                        >{labels?.label}<img src={dropdown} alt="dropdown" /></div>
                        {activeDropdown === 'dropdown-label' && <Options data={options.label} />}
                    </div>}
                    {options?.timespan?.[0] && <div>
                        <div id='date-selector' className='dropdown'>
                            <div
                                id='dropdown-timespan'
                                className='dropdown-select'
                                style={{ borderRight: '1px solid #fff' }}
                                onClick={e => setActiveDropdown(i => i === e.target.id ? null : e.target.id)}
                            >{labels?.timespan}<img src={dropdown} alt="dropdown" /></div>
                            <div id='calender'><img src={calender} alt="calender" /></div>
                            {activeDropdown === 'dropdown-timespan' && <Options data={options.timespan} />}
                        </div>
                    </div>}
                </div>

                <div className='right-actions-container'>
                    {rightSideElements?.includes("searchbar") && <div id='searchbar-container'>
                        <input type="text" className='input-default-theme' placeholder='Search' style={{ width: '15rem' }} />
                        <div id='searchIcon'><img src={searchIcon} alt="" srcset="" width={24} height={24} /></div>
                    </div>}
                    {rightSideElements?.includes("panel") && <div className='dropdown' onClick={() => setActivePopup(true)}><img src={archieve} alt="archieve" /></div>}
                    {rightSideElements?.includes("downlaod") && <div className='dropdown'>
                        <img src={sliders} alt="archieve" onClick={e => setActiveDropdown(d => d === 'transactions-filters' ? null : 'transactions-filters')} />
                        {TransactionFilters && activeDropdown === 'transactions-filters' && <TransactionFilters />}
                    </div>}
                </div>
            </div>
            {activePopup && <POPUP
                btns={["cancel", "export"]}
                Content={Content}
                heading={"Select Export option"}
                close={() => setActivePopup(null)}
                whiteHeader={false}
            />}
        </>
    )
}

export default TableFilters