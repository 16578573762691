import React, { useState } from 'react'
import dustbin from '../assets/icons/dustbin.svg'
import folder from '../assets/icons/folder.svg'
import upload from '../assets/icons/upload.svg'
import dropdown from '../assets/icons/dropdown.svg'
import Options from './options'

const documentTypes = ["Government-Issued ID Card", "Passport", "Driver's License", "Bank statement", "Identity Document ", "bissiness licence ", "income tax licence ",]
const UploadContent = ({ askDocumentType }) => {
    const [activeDropdown, setActiveDropdown] = useState(null)
    return (
        <div style={{ fontWeight: 'bold' }} id="upload-contract">
            {askDocumentType && <div className='default-theme-input'>
                <label htmlFor="api-label">Select Your Document</label>
                <div className='dropdown'>
                    <div
                        id='dropdown-label'
                        className='dropdown-select'
                        onClick={e => setActiveDropdown(i => i === e.target.id ? null : e.target.id)}
                    ><span>Transactions hash</span> <img src={dropdown} alt="dropdown" /></div>
                    {activeDropdown && <Options data={documentTypes} bg={'#FDFEFF'} />}
                </div>
            </div>}
            <p>To add a new Contract, please upload and drag your file here.</p>
            <div id='file-upload' >
                <img src={upload} alt="upload" width={64} height={64} />
                <span>Upload & Drag</span>
            </div>
            <div id='file-detail'>
                <div>
                    <img src={folder} alt="file" />
                    <span>Text file.pdf</span>
                </div>
                <img src={dustbin} alt="file" />
            </div>
        </div>
    )
}

export default UploadContent