import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

export const injected = new InjectedConnector({
  supportedChainIds: [32520, 64668],
});

export const walletConnect = new WalletConnectConnector({
  rpc: { 32520: "https://serverrpc.com" },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  pollingInterval: 12000
});