import { Options, Pagination, TableFilters } from '../../components/index'
import React, { useEffect, useState } from 'react'
import { errorMessage, successMessage } from '../../store/slices/messageSlice'

import { Foundation } from '../../layouts/index'
import PAYBRISE_ABI from "../../abi/abi.json"
import copy from '../../assets/icons/copy.svg'
import httpClient from '../../components/httpClient'
import keccak256 from "keccak256";
import threedots from '../../assets/icons/threedots.svg'
import useConnection from '../../context';
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import DataTable from 'react-data-table-component'

const Layout = () => {
    const { merchant } = useParams();
    const [activeDropdown, setActiveDropdown] = useState(null)
    const [apiData, setApiData] = useState(null);
    const dispatch = useDispatch();

    const { disconnect, connect, account, library, isActive, contractAddress } = useConnection();
    const [loading, setLoading] = useState(false);


    const getProjectDetail = async (projectID) => {
        setLoading(true);
        try {
            var contract = new library.eth.Contract(PAYBRISE_ABI, contractAddress);
            let ProjectDetail = await contract.methods.ProjectDetail(keccak256(projectID)).call();

            return ProjectDetail?.isActive;
        } catch (err) {
            setLoading(false);
        }
    }

    const blockProject = async (projectID) => {
        setLoading(true);
        try {
            var contract = new library.eth.Contract(PAYBRISE_ABI, contractAddress);
            await contract.methods
                .blockProject(keccak256(projectID))
                .send({ from: account })
                .then(async (result) => {
                    // await getMerchantInfo();
                    dispatch(successMessage('commissing changed successfully'))
                    setLoading(false);
                });
        } catch (err) {
            dispatch(errorMessage(err.message))
            setLoading(false);
        }
    }

    const unblockProject = async (projectID) => {
        setLoading(true);
        try {
            var contract = new library.eth.Contract(PAYBRISE_ABI, contractAddress);
            await contract.methods
                .unblockProject(keccak256(projectID))
                .send({ from: account })
                .then(async (result) => {
                    // await getMerchantInfo();
                    dispatch(successMessage('commissing changed successfully'))
                    setLoading(false);
                });
        } catch (err) {
            dispatch(errorMessage(err.message))
            setLoading(false);
        }
    }

    useEffect(() => {
        const apikeyData = async () => {
            const response = await httpClient.getApi(`/admin/merchant/${merchant}/apiKeys`);

            var data = response.data.data;
            let list = [];
            for (let i in data) {
                let row = data[i];
                row.isBlock = await getProjectDetail(row.secretKey)
                list.push(row);
            }
            setApiData(list);
        }
        apikeyData();
    }, []);

    const handleCopy = (str) => {
        navigator.clipboard.writeText(str)
        dispatch(successMessage('Copied Successfully'))
    }

    const convertDate = (inputFormat) => {
        const montsh = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), montsh[pad(d.getMonth())], d.getFullYear()].join(' ')
    }

    if (!apiData) {
        return (
            <>
                <div>
                    <b>Please wait...</b>
                </div>
            </>
        )
    }
    return (
        <div>
            <div id="api">
                <div id="table">
                    <div>
                        <div className='heading-row'>
                            <span>API Keys</span>
                            <div>
                                {isActive ?
                                    <button
                                        className='default-theme-btn'
                                        style={{ marginRight: "10px" }}
                                        onClick={() => disconnect()}
                                    >
                                        Disconnect
                                    </button>
                                    :
                                    <button
                                        className='default-theme-btn'
                                        style={{ marginRight: "10px" }}
                                        onClick={() => connect("metaMask")}
                                    > Connect Wallet
                                    </button>
                                }
                            </div>
                        </div>
                        {/* <TableFilters /> */}
                        <DataTable
                            pagination
                            noDataComponent={(
                                <>
                                    <div>
                                        <b>
                                            No API Keys Found....
                                        </b>
                                    </div>
                                </>
                            )}
                            columns={[
                                {
                                    name: 'Date',
                                    selector: row => convertDate(Date.parse(row['createdAt'])),
                                    sortable: true,
                                },
                                {
                                    name: 'Source',
                                    selector: row => row.source,
                                },
                                {
                                    name: 'CallBack',
                                    selector: row => row.callbackUrl,
                                    sortable: true,
                                },
                                {
                                    name: 'API Key',
                                    selector: row => {
                                        return (
                                            <div onClick={() => handleCopy(row["key"])}>
                                                {row.key} <img src={copy} alt="copy" />
                                            </div>
                                        )
                                    }
                                },
                                {
                                    name: 'Block',
                                    selector: row => {
                                        return (
                                            <>
                                                {isActive ?
                                                    row['isBlock'] ?
                                                        <button
                                                            className="default-theme-btn upper"
                                                            onClick={e => unblockProject()}
                                                            disabled={loading}
                                                        >
                                                            {loading ? "Loading..." : "Unblock"}
                                                        </button> :
                                                        <button
                                                            className="default-theme-btn upper"
                                                            onClick={e => blockProject()}
                                                            disabled={loading}
                                                        >
                                                            {loading ? "Loading..." : "Block"}
                                                        </button>
                                                    :
                                                    <button
                                                        className='default-theme-btn upper'
                                                        style={{ marginRight: "10px" }}
                                                        onClick={() => connect("metaMask")}
                                                    > Connect Wallet
                                                    </button>
                                                }
                                            </>
                                        )
                                    },
                                    sortable: true,
                                },
                                {
                                    name: 'Status',
                                    selector: row => {
                                        return (
                                            <div className={`table-btn ${row["status"]?.toLowerCase()?.replace(' ', '-')}`}>
                                                <button>{row["status"]}</button>
                                            </div>
                                        )
                                    },
                                },
                            ]}
                            data={apiData}
                        />
                        {/* <table>
                            <thead>
                                <tr>
                                    <th>
                                        <div style={{ display: 'flex', gap: '15px' }}>
                                            <input type="checkbox" />
                                            Date
                                        </div>
                                    </th>
                                    <th>Source</th>
                                    <th>CallBack</th>
                                    <th>API key</th>
                                    <th>Block</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {apiData.map((i, n) => (
                                    <tr>
                                        <td>
                                            <div style={{ display: 'flex', gap: '15px' }}>
                                                <input type="checkbox" />
                                                {i["createdAt"]}
                                            </div>
                                        </td>
                                        <td>{i["source"]}</td>
                                        <td>{i["callbackUrl"]}</td>
                                        <td onClick={() => handleCopy(i["key"])}>{i["key"]} <img src={copy} alt="copy" /></td>
                                        <td>
                                            {isActive ?
                                                i['isBlock'] ?
                                                    <button
                                                        className="default-theme-btn upper"
                                                        onClick={e => unblockProject()}
                                                        disabled={loading}
                                                    >
                                                        {loading ? "Loading..." : "Unblock"}
                                                    </button> :
                                                    <button
                                                        className="default-theme-btn upper"
                                                        onClick={e => blockProject()}
                                                        disabled={loading}
                                                    >
                                                        {loading ? "Loading..." : "Block"}
                                                    </button>
                                                :
                                                <button
                                                    className='default-theme-btn upper'
                                                    style={{ marginRight: "10px" }}
                                                    onClick={() => connect("metaMask")}
                                                > Connect Wallet
                                                </button>
                                            }
                                        </td>
                                        <td className={`table-btn ${i["status"]?.toLowerCase()?.replace(' ', '-')}`}>
                                            <button>{i["status"]}</button>
                                        </td>
                                        <td style={{ position: 'relative' }}>
                                            <img src={threedots} alt='options' width={25} height={25} onClick={e => setActiveDropdown(d => d === n ? null : n)} />
                                            {activeDropdown === n && <Options data={["Delete", "Hold/Activate"]} style={{ top: '10px', left: '-60%' }} />}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table> */}

                    </div>
                    {/* <Pagination /> */}
                </div>
            </div >
        </div>
    )
}

const SecretKeysAndAPIs = () => <Foundation Layout={Layout} />
export default SecretKeysAndAPIs