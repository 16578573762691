import React, { useEffect, useState } from 'react'
import { BarChart, Cards, DoughnutChart, Pagination } from '../components/index'
import dropdown from '../assets/icons/dropdown.svg'
import searchIcon from '../assets/icons/search.svg'
import { ReactComponent as store } from '../assets/icons/store.svg'
import { ReactComponent as ticket } from '../assets/icons/ticket.svg'
import { ReactComponent as handshake } from '../assets/icons/handshake.svg'
import { ReactComponent as commission } from '../assets/icons/commission.svg'
import { Foundation } from '../layouts/index'
import httpClient from '../components/httpClient'
import DataTable from 'react-data-table-component';


export const tableData = [
    {
        "Time": "21 July 2022",
        "Transaction Hash": "BSCHash",
        "Amount": "22,780.55",
        "Status": "Successful",
    },
    {
        "Time": "21 July 2022",
        "Transaction Hash": "BSCHash",
        "Amount": "22,780.55",
        "Status": "Fail",
    },
    {
        "Time": "21 July 2022",
        "Transaction Hash": "BSCHash",
        "Amount": "22,780.55",
        "Status": "Successful",
    },
    {
        "Time": "21 July 2022",
        "Transaction Hash": "BSCHash",
        "Amount": "22,780.55",
        "Status": "Pending",
    },
    {
        "Time": "21 July 2022",
        "Transaction Hash": "BSCHash",
        "Amount": "22,780.55",
        "Status": "Successful",
    },
    {
        "Time": "21 July 2022",
        "Transaction Hash": "BSCHash",
        "Amount": "22,780.55",
        "Status": "Successful",
    },
    {
        "Time": "21 July 2022",
        "Transaction Hash": "BSCHash",
        "Amount": "22,780.55",
        "Status": "Fail",
    },
    {
        "Time": "21 July 2022",
        "Transaction Hash": "BSCHash",
        "Amount": "22,780.55",
        "Status": "Pending",
    },
    {
        "Time": "21 July 2022",
        "Transaction Hash": "BSCHash",
        "Amount": "22,780.55",
        "Status": "Pending",
    },
    {
        "Time": "21 July 2022",
        "Transaction Hash": "BSCHash",
        "Amount": "22,780.55",
        "Status": "Fail",
    },
    {
        "Time": "21 July 2022",
        "Transaction Hash": "BSCHash",
        "Amount": "22,780.55",
        "Status": "Fail",
    },
    {
        "Time": "21 July 2022",
        "Transaction Hash": "BSCHash",
        "Amount": "22,780.55",
        "Status": "Successful",
    },
    {
        "Time": "21 July 2022",
        "Transaction Hash": "BSCHash",
        "Amount": "22,780.55",
        "Status": "Successful",
    },
    {
        "Time": "21 July 2022",
        "Transaction Hash": "BSCHash",
        "Amount": "22,780.55",
        "Status": "Successful",
    },
    {
        "Time": "21 July 2022",
        "Transaction Hash": "BSCHash",
        "Amount": "22,780.55",
        "Status": "Successful",
    },
]

const cardsData = {
    type1: [
        {
            title: 'Transactions',
            left_text: { value: '120', title: 'Txn Count' },
            right_text: { value: '993', label: '(BRISE)' },
        },
        {
            title: 'Success Transactions',
            left_text: { value: '120', title: 'Txn Count' },
            right_text: { value: '993', label: '(BRISE)' },
        },
        {
            title: 'Failed Transactions',
            left_text: { value: '90', title: 'Fail Count' },
            right_text: { value: '993', label: '(BRISE)' },
        },
        {
            title: 'Pending Transactions',
            left_text: { value: '90', title: 'Fail Count' },
            right_text: { value: '993', label: '(BRISE)' },
        },
    ],
    type2: [
        {
            title: 'Merchants Count',
            Logo: store,
            values: [
                { value: '13', title: 'Registers Merchants' },
                { value: '13', title: 'Pending Merchants' },
            ]
        },
        {
            title: 'Support Ticket',
            Logo: ticket,
            values: [
                { value: '13', title: 'Total Tickets Count' },
                { value: '13', title: 'Pending Tickets' },
            ]
        },
    ]
}

const Layout = () => {
    let totalSum = 0;
    let successCount = 0;
    let failCount = 0;
    let pendingCount = 0;
    let failSum = 0;
    let successSum = 0;
    let pendingSum = 0;
    let ticketStatus = {
        total: 0,
        open: 0,
    };

    const [overViewData, setOverViewData] = useState();
    const [transactions, setTransactions] = useState();

    const [support, setSupport] = useState();

    const convertDate = (inputFormat) => {
        const montsh = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), montsh[pad(d.getMonth())], d.getFullYear()].join(' ')
    }

    useEffect(() => {
        const loadTransactions = async () => {
            const response = await httpClient.getApi('/admin/transactions');
            setTransactions(response.data.data);
        };

        const loadOverViewData = async () => {
            const response = await httpClient.getApi('/admin/transactions/overview');
            setOverViewData(response.data.data);
        };

        const loadSupportOverview = async () => {
            const response = await httpClient.getApi('/admin/support/overview');
            setSupport(response.data.data);
        }

        loadSupportOverview();
        loadOverViewData();
        loadTransactions();
    }, []);


    if (overViewData && transactions) {
        if(support) {
            let totalTicketCount = 0;
            let openTicketCount = 0;
            support.forEach((e) => {
                if(e._id === 'open') openTicketCount++;
                totalTicketCount += e.count || 0;
            });
            ticketStatus.open = openTicketCount;
            ticketStatus.total = totalTicketCount;
        }
        transactions?.forEach((e) => {
            if (e.status === 'success') {
                successSum = successSum + parseFloat(e.amount);
                successCount++;
            }
            if (e.status === 'pending') {
                pendingSum = pendingSum + parseFloat(e.amount);
                pendingCount++;
            }
            if (e.status === 'failed') {
                failSum = failSum + parseFloat(e.amount);
                failCount++;
            }
            totalSum += parseFloat(e.amount);
        });
        cardsData.type1 = [
            {
                title: 'Transactions',
                left_text: { value: transactions.length, title: 'Txn Count' },
                right_text: { value: totalSum, label: '(BRISE)' },
            },
            {
                title: 'Success Transactions',
                left_text: { value: successCount, title: 'Txn Count' },
                right_text: { value: successSum, label: '(BRISE)' },
            },
            {
                title: 'Failed Transactions',
                left_text: { value: failCount, title: 'Fail Count' },
                right_text: { value: failSum, label: '(BRISE)' },
            },
            {
                title: 'Pending Transactions',
                left_text: { value: pendingCount, title: 'Fail Count' },
                right_text: { value: pendingSum, label: '(BRISE)' },
            },
        ];
        cardsData.type2 = [
            {
                title: 'Merchants Count',
                Logo: store,
                values: Array.from(overViewData.merchantStatus, (e) => {
                    return { value: e.count, title: e._id === true ? 'Active' : 'Inactive' }
                })
            },
            {
                title: 'Support Ticket',
                Logo: ticket,
                values: [
                    { value: ticketStatus.total, title: 'Total Tickets Count' },
                    { value: ticketStatus.open, title: 'Pending Tickets' },
                ]
            }
        ];
    }


    if (!overViewData || !transactions) return (
        <>
            <h5>Please Wait...</h5>
        </>
    )

    

    return (
        <div className='dashboard'>
            <div>
                <div id="your-business">
                    <Cards heading={'Your Business'} showType2={true} cardsData={cardsData} />
                </div>
            </div>

            <div id='dashboard-content-container'>
                <div id='left-side'>
                    <div id="your-business">
                        <div id='bar-char-wrapper'>
                            <BarChart rawData={overViewData.barChartData} />
                        </div>
                    </div>
                    <div id="table">
                        <div>
                            <div id='table-wrapper'>
                                <DataTable
                                    title='Transactions'
                                    pagination
                                    noDataComponent={(
                                        <>
                                            <div>
                                                <b>
                                                    No Transactions Found....
                                                </b>
                                            </div>
                                        </>
                                    )}
                                    columns={[
                                        {
                                            name: 'Time',
                                            selector: row => convertDate(Date.parse(row['createdAt'])),
                                            sortable: true,
                                        },
                                        {
                                            name: 'Transaction Hash',
                                            selector: row => row.txHash,
                                        },
                                        {
                                            name: 'Amount',
                                            selector: row => row.amount,
                                            sortable: true,
                                        },
                                        {
                                            name: 'Status',
                                            selector: row => {
                                                return (<div className={`table-btn ${row.status?.toLowerCase() === 'success' ? 'successful' : ''} ${row.status?.toLowerCase() === 'failed' ? 'fail' : ''}`}><button>{row.status.toUpperCase()}</button></div>)
                                            },
                                        },
                                    ]}
                                    data={transactions}
                                />
                            </div>

                        </div>
                        {/* <Pagination /> */}
                    </div>
                </div>

                <div id='right-side'>
                    <div className="pie-chart" style={{ width: "250", height: "250" }}>
                        <h2>Monthly transactions</h2>
                        <div className='pie-chart-wrapper'><DoughnutChart rawData={overViewData.donutData} /></div>
                    </div>
                    {/* <div className="pie-chart" style={{ width: "250", height: "250" }}>
                        <h2>Settlement</h2>
                        <div className='pie-chart-wrapper'><DoughnutChart /></div>
                    </div>
                    <div className="pie-chart" style={{ width: "250", height: "250" }}>
                        <h2>Sources</h2>
                        <div className='pie-chart-wrapper'><DoughnutChart /></div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

const Dashboard = () => <Foundation Layout={Layout} />
export default Dashboard