import './styles/App.css';
import './styles/layouts.css';
import './styles/pages.css';

import * as Pages from './pages/index';

import { Navigate, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import ProtectedRoute from './components/ProtectedRoute';

const App = () => {

  const { auth } = useSelector(state => state);
  const dispatch = useDispatch();
  return (

    <div className="App">
      <Routes>
        <Route path="/" element={<Pages.LogoPage />} />
        <Route path="/login" element={<Pages.Login />} />
        <Route path="/two-step-verification" element={<Pages.TwoStepVerification />} />
        <Route path="/forgot-password" element={<Pages.ForgotPassword />} />
        <Route path="/reset-password/:token" element={<Pages.ResetPassword />} />
        <Route path="/signup" element={<Pages.SignUp />} />

        <Route path="/dashboard" element={
          <ProtectedRoute auth={auth} >
            <Pages.Dashboard />
          </ProtectedRoute>
        }
        />
        <Route path="/transactions" element={
          <ProtectedRoute auth={auth} >
            <Pages.Transactions />
          </ProtectedRoute>
        }
        />
        <Route path="/commission" element={
          <ProtectedRoute auth={auth} >
            <Pages.Commission />
          </ProtectedRoute>
        }
        />
        <Route path="/advertisements" element={
          <ProtectedRoute auth={auth} >
            <Pages.Advertisements />
          </ProtectedRoute>
        }
        />
        <Route path="/support-ticket" element={
          <ProtectedRoute auth={auth} >
            <Pages.SupportTicket />
          </ProtectedRoute>
        }
        />

        <Route path="/merchant" element={
          <ProtectedRoute auth={auth} >
            <Pages.Merchants />
          </ProtectedRoute>
        }
        />

        <Route path="/merchant/:merchant/transactions" element={
          <ProtectedRoute auth={auth} >
            <Pages.MerchantTransactions />
          </ProtectedRoute>
        }
        />

        <Route path="/merchant/:merchant/profile-details" element={
          <ProtectedRoute auth={auth} >
            <Pages.BussinessDetails />
          </ProtectedRoute>
        }
        />

        <Route path="/merchant/:merchant/documents-verification" element={
          <ProtectedRoute auth={auth} >
            <Pages.DocumentsVerification />
          </ProtectedRoute>
        }
        />

        <Route path="/merchant/:merchant/total-payment-received" element={
          <ProtectedRoute auth={auth} >
            <Pages.TotalPaymentReceived />
          </ProtectedRoute>
        }
        />

        <Route path="/merchant/:merchant/total-commission-paid" element={
          <ProtectedRoute auth={auth} >
            <Pages.TotalCommissionPaid />
          </ProtectedRoute>
        }
        />

        <Route path="/merchant/:merchant/secret-key-and-api-key" element={
          <ProtectedRoute auth={auth} >
            <Pages.SecretKeysAndAPIs />
          </ProtectedRoute>
        }
        />

        <Route path="/setting" element={<Navigate to={"commission-rate"} />} />

        <Route path="/setting/commission-rate" element={
          <ProtectedRoute auth={auth} >
            <Pages.CommissionRate />
          </ProtectedRoute>
        }
        />

        <Route path="/setting/change-password" element={
          <ProtectedRoute auth={auth} >
            <Pages.ChangePassword />
          </ProtectedRoute>
        }
        />

        <Route path="/setting/change-avatar" element={
          <ProtectedRoute auth={auth} >
            <Pages.ChangeAvatar />
          </ProtectedRoute>
        }
        />

        <Route path="/setting/contract-setting" element={
          <ProtectedRoute auth={auth} >
            <Pages.contractSetting />
          </ProtectedRoute>
        }
        />

      </Routes>
    </div>
  );
};

export default App;