import React, { useState } from 'react'
import { Foundation } from '../../layouts/index'
import eyeIcon from '../../assets/icons/eye.svg'
import { useDispatch } from 'react-redux'
import { errorMessage, successMessage } from '../../store/slices/messageSlice'
import httpClient from '../../components/httpClient'

const Layout = () => {
    const [passwords, setPasswords] = useState({})
    const [showPassword, setShowPassword] = useState(false)

    const handlePasswordVisibility = e => {
        const id = e.target.parentNode.parentElement.getAttribute('for')
        setShowPassword(showPassword === id ? false : id)
    }

    return (
        <div className={"default-theme-input change-password-wrapper blur-inputs"}>
            <h2>Change Password</h2>
            {/* <small>Manage your password</small>
            <label htmlFor="current-password">
                <span>Current Password</span>
                <div className='label-icon' onClick={handlePasswordVisibility}>
                    <img src={eyeIcon} alt="show password" />
                </div>
            </label>
            <input
                id='current-password'
                type={showPassword === 'current-password' ? "text" : "password"}
                value={passwords?.currentPassword}
                onChange={e => setPasswords(curr => ({ ...curr, currentPassword: e.target.value }))}
                minLength={8}
                required
            />
            <label htmlFor="new-password">
                <span>New Password</span>
                <div className='label-icon' onClick={handlePasswordVisibility}>
                    <img src={eyeIcon} alt="show password" />
                </div>
            </label>
            <input
                id='new-password'
                type={showPassword === 'new-password' ? "text" : "password"}
                value={passwords?.newPassword}
                onChange={e => setPasswords(curr => ({ ...curr, newPassword: e.target.value }))}
                minLength={8}
                required
            />
            <label htmlFor="confirm-new-password">
                <span>Confirm New Password</span>
                <div className='label-icon' onClick={handlePasswordVisibility}>
                    <img src={eyeIcon} alt="show password" />
                </div>
            </label>
            <input
                id='confirm-new-password'
                type={showPassword === 'confirm-new-password' ? "text" : "password"}
                value={passwords?.confirmPassword}
                onChange={e => setPasswords(curr => ({ ...curr, confirmPassword: e.target.value }))}
                minLength={8}
                required
            /> */}
            <br />
            <ResetPasswordForm />
        </div>
    )
}

export const ResetPasswordForm = ({ callback }) => {
    const [passwords, setPasswords] = useState({})
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false)
    const dispatch = useDispatch();

    const handlePasswordVisibility = e => {
        const id = e.target.parentNode.parentElement.getAttribute('for')
        setShowPassword(showPassword === id ? false : id)
    }

    const onsubmit = async e => {
        e.preventDefault();
        try {
            setLoading(true);
            if(passwords.newPassword !== passwords.confirmPassword) {
                setLoading(false);
                alert('new password and confirm password is not matching');
                return;
            }
            await httpClient.postApi('/user/change-password', { oldPassword: passwords.oldPassword, newPassword: passwords.newPassword });
            dispatch(successMessage('Password changed!!'))
            setLoading(false);
        } catch(e) {
            setLoading(false);
            console.log(e);
            dispatch(errorMessage('Failed to update password, please verify if password containts 8 or more characters with mix of letters, numbers, and symbols. '))
        }
    }

    return (
        <form id='user-auth-form' onSubmit={onsubmit}>

            <label htmlFor="oldpassword">
                <span>Current Password</span>
                <div className='label-icon' onClick={handlePasswordVisibility}>
                    <img src={eyeIcon} alt="show password" />
                </div>
            </label>
            <input
                id='oldpassword'
                type={showPassword === 'oldpassword' ? "text" : "password"}
                value={passwords?.oldPassword}
                onChange={e => setPasswords(curr => ({ ...curr, oldPassword: e.target.value }))}
                minLength={8}
                required
            />

            <label htmlFor="password">
                <span>New Password</span>
                <div className='label-icon' onClick={handlePasswordVisibility}>
                    <img src={eyeIcon} alt="show password" />
                </div>
            </label>

            <input
                id='password'
                type={showPassword === 'password' ? "text" : "password"}
                value={passwords?.newPassword}
                onChange={e => setPasswords(curr => ({ ...curr, newPassword: e.target.value }))}
                minLength={8}
                required
            />

            <label htmlFor="confirm-password">
                <span>Confirm Password</span>
                <div className='label-icon' onClick={handlePasswordVisibility}>
                    <img src={eyeIcon} alt="show password" />
                </div>
            </label>
            <input
                id='confirm-password'
                type={showPassword === 'confirm-password' ? "text" : "password"}
                value={passwords?.confirmPassword}
                onChange={e => setPasswords(curr => ({ ...curr, confirmPassword: e.target.value }))}
                minLength={8}
                required
            />

            <p id='reset-password-message'>Use minimum 8 or more characters with mix of letters, numbers, and symbols.</p>
            <button className='default-theme-btn'>{loading ? 'Please wait...' : 'Save' }</button>
            {/* <button type={"submit"}>{loading ? 'Please wait' : 'Reset' }</button> */}
        </form>
    )
}

const ChangePassword = () => <Foundation Layout={Layout} />
export default ChangePassword