import React, { useState } from 'react'
import LoginImage from '../../assets/images/login.png'
import { AuthLayout } from '../../layouts'

const TwoStepVerification = () => <AuthLayout
    imgsrc={LoginImage}
    Heading={"Two-step verification"}
    Type={"two-step-verification"}
/>

export const TwoStepVerificationForm = () => {

    const [code, setCode] = useState()
    return (
        <form id='user-auth-form'>
            <div className='message'>
                <div>Thanks for keeping your account secure.</div>
                <div>Check your email: <b>XY**@gmail.com</b></div>
            </div>

            <label htmlFor="text">Your Verification Code</label>
            <input
                type="text"
                id='text'
                value={code}
                onChange={e => setCode(e.target.value)}
                maxLength={6}
                minLength={6}
                placeholder={"6-Digit Code"}
                required
            />

            <div id='dont-ask-container'>
                <input type="checkbox" name="dont-ask-again" id="dont-ask-again" />
                <div>
                    <label htmlFor="dont-ask-again">This Is A Trusted Device, Don't Ask Again.</label>
                    <span id='learn-more' className='brise-theme-font'>Learn More</span>
                </div>
            </div>
            <button>SIGN IN</button>
        </form>
    )
}

export default TwoStepVerification