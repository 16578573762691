import React, { useState } from 'react'
import { AuthLayout } from '../../layouts/index'
import LoginImage from '../../assets/images/login.png'
import eyeIcon from '../../assets/icons/eye.svg'
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { login } from '../../store/slices/authSlice';
import { errorMessage } from '../../store/slices/messageSlice';
const Login = () => <AuthLayout
  imgsrc={LoginImage}
  Heading={"Login to your Paybrise admin account"}
  Type={"login"}
/>

export const LoginForm = () => {


  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false);
  const [isProcessing, setIsProcessing] = useState('SIGN IN');

  const [input, setInput] = useState({
    email: '',
    password: '',
  });

  const [error, setError] = useState({
    email: '',
    password: ''
  })

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsDisabled(true);
    setIsProcessing('Please Wait...');
    const dataInput = {
      password: input.password,
      email: input.email
    }
    const inputResponse = JSON.stringify(dataInput);
    dispatch(login(inputResponse)).unwrap()
      .then((data) => {
        if (data.user.data.role === 'admin') {
          localStorage.setItem('jt_access_token', data.user.data.access.token);
          localStorage.setItem('jt_refresh_token', data.user.data.refresh.token);
          localStorage.setItem('jt_user_info', JSON.stringify(data.user.data));
          navigate('/dashboard');
          return;
        }
        setIsDisabled(false);
        setIsProcessing('SIGN IN');
        dispatch(errorMessage('not authorized!!!'))
      }).
      catch((err) => {
        setIsDisabled(false);
        setIsProcessing('SIGN IN');
      })
  }

  const onInputChange = e => {
    const { name, value } = e.target;
    setInput(prev => ({
      ...prev,
      [name]: value
    }));
  }

  return (

    <form id='user-auth-form' onSubmit={handleSubmit}>

      <label htmlFor="email">Email</label>
      <input
        type="email"
        id='email'
        name='email'
        value={input.email}
        onChange={onInputChange}
        required
      />

      <label htmlFor="password">
        <span>Password</span>
        <div className='label-icon' onClick={() => setShowPassword(!showPassword)}>
          <img src={eyeIcon} alt="show password" />
        </div>
      </label>
      <input
        id='password'
        type={showPassword ? "text" : "password"}
        name="password"
        value={input.password}
        onChange={onInputChange}
        required
      />

      <p style={{ cursor: 'pointer' }} onClick={e => navigate(`/forgot-password`)}>Forgot Password</p>

      <button disabled={isDisabled} >{isProcessing}</button>
    </form>
  )
}

export default Login