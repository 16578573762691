import React from 'react'
import profilePicture from '../../assets/images/profile-picture-large.png'
import { Foundation } from '../../layouts/index'
import avatar1 from '../../assets/images/avatars/avatar1.png'
import avatar2 from '../../assets/images/avatars/avatar2.png'
import avatar3 from '../../assets/images/avatars/avatar3.png'
import avatar4 from '../../assets/images/avatars/avatar4.png'
import avatar5 from '../../assets/images/avatars/avatar5.png'
import avatar6 from '../../assets/images/avatars/avatar6.png'

const avatars = { avatar1, avatar2, avatar3, avatar4, avatar5, avatar6 }
const Layout = () => {
    return (
        <div id='change-avatar'>
            <div id='profile-wrapper'>
                <div id='profile'>
                    <div className='profile-pricture-wrapper' style={{ width: '180px', height: '180px' }}>
                        <img src={profilePicture} alt="user-profile" width={164} height={164} />
                    </div>
                    <div id='profile-detail'>
                        <h3 style={{ marginBottom: "4px" }}>Alex User</h3>
                        <span>abc@gmail.com</span>
                    </div>
                </div>
                <button className="default-theme-btn upper">SAVE</button>
            </div>
            <div id='avatars'>
                <div>{Object.values(avatars)?.map(src => <div><img src={src} alt="src" width={106} /></div>)}</div>
                <div>{Object.values(avatars)?.map(src => <div><img src={src} alt="src" width={106} /></div>)}</div>
            </div>
        </div>
    )
}

const ChangeAvatar = () => <Foundation Layout={Layout} />
export default ChangeAvatar