import React, { useEffect, useState } from 'react'
import { Cards, Pagination, Options, TableFilters } from '../../components/index'
import { Foundation } from '../../layouts/index'
import threedots from '../../assets/icons/threedots.svg'
import nodata from '../../assets/images/nodata.png'
import { useNavigate } from 'react-router-dom'
import httpClient from '../../components/httpClient'
import DataTable from 'react-data-table-component'
const cardsData = {}
const options = ["All", "Verified merchants", "Non Verified merchants"]
const MerchantPages = ({ selected, setSelected }) => {
    return (
        <div id='setting-options'>
            <div className="linear-options settings">
                {options?.map(i => <button
                    className={selected === options.indexOf(i) ? 'selected' : ''}
                    style={{ cursor: 'pointer' }}
                    onClick={() => (options.indexOf(i) <= 2) && setSelected(options.indexOf(i))}
                >{i}</button>)}
            </div>
        </div>
    )
}

const Layout = () => {
    
    const Navigate = useNavigate()
    // const [activeDropdown, setActiveDropdown] = useState(null)
    const [selected, setSelected] = useState(0)
    const [merchants, setMerchants] = useState();
    const [filterInput, setFilterInput] = useState("");
    const handleFilterChange = e => {
        const value = e.target.value || undefined;
        setFilterInput(value);
    };

    useEffect(() => {
        const loadAllMerchants = async () => {
            const result = await httpClient.getApi('/admin/merchants/all');
            cardsData.type1 = [
                {
                    title: 'All merchants',
                    hideLogo: true,
                    right_text: { value: result.data.data.length },
                },
                {
                    title: 'Verified merchants',
                    hideLogo: true,
                    right_text: { value: result.data.data.filter((e) => e.user.isVerified).length },
                },
                {
                    title: 'Non Verified merchants',
                    hideLogo: true,
                    right_text: { value: result.data.data.filter((e) => !e.user.isVerified).length },
                }
            ];
            setMerchants(result.data.data);
        };
        loadAllMerchants();
    }, []);



    const filterData = () => {

        const pageFilter = (type) => {
            if (!merchants) return [];
            return merchants.filter((e) => {
                if (selected === 0) return true;
                if (selected === 1) return e.user.isVerified;
                return !e.user.isVerified;
            })
        }

        const pageFilterOutput = pageFilter();

        if (filterInput && filterInput !== "") {
            return pageFilterOutput.filter((e) => {
                if (e.user.email || e.user.firstName || e.user.lastName) {
                    return (e.user.email || e.user.firstName || e.user.lastName).trim().toLowerCase().includes(filterInput.toLowerCase())
                }
                return false;
            });
        }

        return pageFilterOutput;
    }

    if (!merchants) {
        return (
            <>
                <b>
                    Please Wait...
                </b>
            </>
        )
    }

    const convertDate = (inputFormat) => {
        const montsh = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), montsh[pad(d.getMonth())], d.getFullYear()].join(' ')
    }

    return (
        <div style={{ background: "#EFF3F9", padding: "15px" }}>
            <div id="your-business">
                <Cards heading={'Merchants Overview'} cardsData={cardsData} showFilters={false} />
            </div>
            <table style={{ width: '100%' }}>
                <tr>
                    <td>
                        <MerchantPages selected={selected} setSelected={setSelected} />
                    </td>
                    <td>
                        <div id='user-auth-form'>
                            <input
                                value={filterInput}
                                onChange={handleFilterChange}
                                placeholder={"Search Merchant"}
                            />
                        </div>
                    </td>
                </tr>
            </table>


            <div id="table">
                <div>
                    {/* <TableFilters TransactionFilters={TransactionFilters} options={dropDownOptions} labels={{ label: "Name", timespan: "Past 30 days" }} />
                    <div className='line l1' />
                    <div className='line l2' /> */}
                    <DataTable
                        title='Merchants'
                        pagination
                        noDataComponent={(
                            <>
                                <div>
                                    <b>
                                        No Merchants Found....
                                    </b>
                                </div>
                            </>
                        )}
                        onRowClicked={row => {
                            Navigate(`${row["_id"]}/profile-details`);
                        }}
                        columns={[
                            {

                                name: 'Owner Name',
                                selector: row => (`${row.user.firstName} ${row.user.lastName}`),
                                sortable: true,
                            },
                            {
                                name: 'Business Name',
                                selector: row => row.displayName,
                                sortable: true,
                            },
                            {
                                name: 'Country',
                                selector: row => row.user.country,
                                sortable: true,
                            },
                            {
                                name: 'Business Type',
                                selector: row => row.businessType,
                                sortable: true,
                            },
                            {
                                name: 'Email Address',
                                selector: row => row.user.email,
                            },
                            {
                                name: 'Date of registration',
                                selector: row => convertDate(row.createdAt)
                            },
                            {
                                name: 'Status',
                                selector: row => {
                                    return (
                                        <div className={`table-btn ${!row.user.isVerified ? 'fail' : 'verified'}`}>
                                            <button>{row.user.isVerified ? 'Verified' : 'Non Verified'}</button>
                                        </div>
                                    );
                                },
                            },
                        ]}
                        data={filterData(selected)}
                    />
                    {/* <table >
                        <thead>
                            <tr>
                                <th>Owner Name</th>
                                <th>Business Name</th>
                                <th>Country</th>
                                <th>Business Type</th>
                                <th>Email Address</th>
                                <th>Date of registration</th>
                                <th>Verification Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filterData(selected).map((i, n) => (
                                <tr style={{ cursor: "pointer" }} onClick={e => Navigate(`${i["_id"]}/profile-details`)}>
                                    <td>{i.user.firstName} {i.user.lastName}</td>
                                    <td>{i.displayName}</td>
                                    <td>{i.user.country}</td>
                                    <td>{i.businessType}</td>
                                    <td>{i.user.email}</td>
                                    <td>{convertDate(i.createdAt)}</td>
                                    <td className={`table-btn ${!i.user.isVerified ? 'fail' : 'verified'}`}>
                                        <button>{i.user.isVerified ? 'Verified' : 'Non Verified'}</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table> */}
                </div>
                {/* <Pagination /> */}
            </div>
        </div >
    )
}

const VerifiedMerchants = () => <Foundation Layout={Layout} />
export default VerifiedMerchants