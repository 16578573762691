import React, { useState } from 'react'
import { AuthLayout } from '../../layouts/index'
import imagesrc from '../../assets/images/signup.jpeg'
import eyeIcon from '../../assets/icons/eye.svg'
import dropDownIcon from '../../assets/icons/dropdown.svg'

const SignUp = () => <AuthLayout
  imgsrc={imagesrc}
  Heading={"Create your Paybrise account "}
  Type={"signup"}
/>

export const SignUpForm = () => {

  const [showPassword, setShowPassword] = useState(false)
  const [credentials, setCredentials] = useState({})

  return (
    <form id='user-auth-form'>
      <label htmlFor="email">Email or Mobile Number</label>
      <input
        type="text"
        id='email'
        value={credentials?.email}
        onChange={e => setCredentials(curr => ({ ...curr, email: e.target.value }))}
        required
      />

      <label htmlFor="password">
        <span>Password</span>
        <div className='label-icon' onClick={() => setShowPassword(!showPassword)}>
          <img src={eyeIcon} alt="show password" />
        </div>
      </label>
      <input
        id='password'
        type={showPassword ? "text" : "password"}
        value={credentials?.password}
        onChange={e => setCredentials(curr => ({ ...curr, password: e.target.value }))}
        required
      />

      <label htmlFor="confirm-password">
        <span>Confirm Password</span>
        <div className='label-icon' onClick={() => setShowPassword(!showPassword)}>
          <img src={eyeIcon} alt="show confirm password" />
        </div>
      </label>
      <input
        id='confirm-password'
        type={showPassword ? "text" : "password"}
        value={credentials?.confirmPassword}
        onChange={e => setCredentials(curr => ({ ...curr, confirmPassword: e.target.value }))}
        required
      />


      <label>Do you want to collect payments on your website</label>
      <div id='collect-on-web'>
        <div>
          <input type="radio" name="collect-on-website" id="collect-on-web-y" defaultChecked />
          <label htmlFor="collect-on-web-y">Yes</label>
        </div>

        <div>
          <input type="radio" name="collect-on-website" id="collect-on-web-n" />
          <label htmlFor="collect-on-web-n">No</label>
        </div>
      </div>
      <input
        id='website-url'
        type={'url'}
        placeholder={'Enter your website URL'}
        required
      />


      <label htmlFor="monthly-expected-sales">
        <span>Monthly expected sales</span>
        <div className='label-icon dropdownIco'>
          <img src={dropDownIcon} alt="dropdown" />
        </div>
      </label>
      <input type="hidden" required />
      <div
        id='monthly-expected-sales'
        className='sales-dropdown'
      >Please select expected sales</div>

      <button>Create Account</button>
    </form>
  )
}

export default SignUp